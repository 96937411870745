import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";
import { Link, useNavigate } from "react-router-dom";
import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { Routes, Route } from 'react-router-dom';

import Topbar from "../../components/react-dashboard/global/Topbar";
import Sidebar from "../../components/react-dashboard/global/Sidebar";
import Dashboard from "../../components/react-dashboard/dashboard";
import Users from "../../components/react-dashboard/users";
import OrganizationForm from "../../components/react-dashboard/org_form";
import Organizations from "../../components/react-dashboard/organizations";
import Atgs from "../../components/react-dashboard/atgs";
import Organization from "../../components/react-dashboard/organization";
import UserForm from "../../components/react-dashboard/user_form";
import Atg from "../../components/react-dashboard/atg";
import User from "../../components/react-dashboard/user";
import UserEditForm from "../../components/react-dashboard/edit_user";
import ResetPassword from "../../components/react-dashboard/user_actions/ResetPassword";


import RequireAuth from "./RequireAuth";
import OrganizationEditForm from "../../components/react-dashboard/edit_org";
import AdminSubscriptionManager from "../../components/react-dashboard/organization/forms/AdminSubscriptionManager";

const Console = () => {
    const [theme, colorMode] = useMode();

    const content = (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box display="flex" width="100%" height="100vh">
                    <Sidebar />
                    <Box component="main" width="100%" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                        <Topbar />
                        <Box flex="1">
                            <Routes>
                                <Route element={<RequireAuth />}>
                                    <Route path="/" element={<Dashboard />} />
                                    <Route path="/reset-password" element={<ResetPassword />} />
                                    <Route path="/users/*" element={<Users />} />
                                    <Route path="/users/:username" element={<User />} />
                                    <Route path="/users/:username/edit" element={<UserEditForm />} />
                                    <Route path="/organizations/*" element={<Organizations />} />
                                    <Route path="/organization/:organization_id" element={<Organization />} />
                                    <Route path="/organization/:organization_id/edit" element={<OrganizationEditForm />} />
                                    <Route path="/organization/:organization_id/subscription-manager" element={<AdminSubscriptionManager />} />
                                    <Route path="/atgs/:atg_name" element={<Atg />} />
                                    <Route path="/organization/:organization_id/users/new" element={<UserForm />} />
                                    <Route path="/organizations/new" element={<OrganizationForm />} />
                                    <Route path="/users/new" element={<UserForm />} />
                                    <Route path="/atgs/*" element={<Atgs />} />

                                </Route>
                            </Routes>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );

    return content;
};

export default Console;
