import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Paper,
    useTheme,
    useMediaQuery,
    IconButton,
    Menu,
    MenuItem
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { tokens } from '../../../../theme';
import InventoryHistory from './components/InventoryHistory';
import { useGetAtgQuery } from '../../../../features/atgs/atgsApiSlice';
import { usfuelblack } from '../../../../assets';
import USFuelCSLD from './components/USFuelCSLD';

const ReportsTab = ({ atg, atg_name, config }) => {
    const [selectedSection, setSelectedSection] = useState('InventoryHistory');
    const { data: atgReturn } = useGetAtgQuery(atg_name, { refetchOnMountOrArgChange: true });
    const atgAttributes = atgReturn?.attributes;

    const [logoImg, setLogoImg] = useState(null);
    useEffect(() => {
        fetch(usfuelblack)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setLogoImg(base64data);
                };
            });
    }, []);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSectionSelect = (section) => {
        setSelectedSection(section);
        handleMenuClose();
    };

    const SidebarContent = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.primary[700],
                height: '100%',
                padding: '20px',
                width: isMobile ? '200px' : '220px',
            }}
        >
            <Typography variant="h3" sx={{ color: colors.primary[100], fontWeight: 'bold', mb: 2 }}>
                Reports:
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    cursor: 'pointer',
                    color: selectedSection === 'InventoryHistory' ? colors.greenAccent[400] : colors.primary[200],
                    mb: 2
                }}
                onClick={() => handleSectionSelect('InventoryHistory')}
            >
                Inventory
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    cursor: 'pointer',
                    color: selectedSection === 'USFuelCSLD' ? colors.greenAccent[400] : colors.primary[200],
                }}
                onClick={() => handleSectionSelect('USFuelCSLD')}
            >
                USFuel CSLD
            </Typography>
        </Box>
    );

    return (
        <Box display="flex" height="100%" width="100%">
            {/* Desktop Sidebar */}
            {!isMobile && (
                <Box>
                    {SidebarContent}
                </Box>
            )}

            {/* Main Content Area */}
            <Box flex="1" overflow="auto" sx={{ position: 'relative' }}>
                {isMobile && (
                    // A small top bar (like a toolbar) for the hamburger icon on mobile
                    <Box
                        sx={{
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingX: '10px',
                            backgroundColor: colors.primary[700],
                            position: 'sticky',
                            top: 0,
                            zIndex: 2000,
                        }}
                    >
                        <IconButton onClick={handleMenuOpen} sx={{ color: '#fff' }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h3" sx={{ color: colors.primary[100], fontWeight: 'bold', mb: .6 }}>
                            Reports:
                        </Typography>
                    </Box>
                )}

                {/* Mobile Menu */}
                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem
                        onClick={() => handleSectionSelect('InventoryHistory')}
                        sx={{ color: selectedSection === 'InventoryHistory' ? colors.greenAccent[400] : colors.primary[700] }}
                    >
                        Inventory
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleSectionSelect('USFuelCSLD')}
                        sx={{ color: selectedSection === 'USFuelCSLD' ? colors.greenAccent[400] : colors.primary[700] }}
                    >
                        USFuel CSLD
                    </MenuItem>
                </Menu>

                {/* Main Content with appropriate padding so content is below the toolbar on mobile */}
                <Box sx={{ padding: isMobile ? "10px" : "20px" }}>
                    {selectedSection === 'InventoryHistory' && (
                        <InventoryHistory atg_name={atg_name} atgAttributes={atgAttributes} atg={atg} config={config} logoImg={logoImg} />
                    )}
                    {selectedSection === 'USFuelCSLD' && (
                        <USFuelCSLD atg_name={atg_name} config={config} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ReportsTab;
