import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Button, MenuItem, InputLabel, Select, FormControl, Box, Grid } from "@mui/material";
import AtgReadingForm from './forms/AtgReadingForm';
import {
    useGetAtgMeterReadingsByAtgNameQuery,
    useUpdateShiftStartTimeMutation,
    useGetShiftStartTimeByAtgNameQuery
} from '../../../../features/organizations/organizationsApiSlice';
import { useGetAtgReportsByMonthQuery, useGetAtgQuery, useGetHistoricalDeliveryDataQuery, useGetHistoricalLeakDataQuery, useGetATGConfigDataQuery } from '../../../../features/atgs/atgsApiSlice';
import DailySummary from './components/DailySummary';
import DeliveryReports from './components/DeliveryReports';
import LeakTestReports from './components/LeakTestReports';
import ATGDateSelector from './components/ATGDateSelector';




const InventoryTab = ({ atg, attributes }) => {
    const { atg_name } = useParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [shiftStartTime, setShiftStartTime] = useState(0); // New state for shift start time
    const [deliveries, setDeliveries] = useState([]);
    const [leakTests, setLeakTests] = useState([]);

    const [updateShiftStartTime, { isSuccess: isUpdateTimeSuccess }] = useUpdateShiftStartTimeMutation();
    const { data: shiftStartTimeData, isLoading: isShiftStartTimeLoading } = useGetShiftStartTimeByAtgNameQuery({ atg_name });

    console.log("Shift start time: ", isShiftStartTimeLoading ? "Loading..." : shiftStartTime);

    // Fetch delivery data
    const { data: configData } = useGetATGConfigDataQuery(atg_name, { skip: !atg_name });

    const { data: deliveryReports = [], isLoading: isLoadingDeliveries, isError: isErrorDeliveries } =
        useGetHistoricalDeliveryDataQuery({
            atg_name,
            year: selectedDate.getFullYear(),
            month: selectedDate.getMonth() + 1
        });

    const { data: leakReports = [], isLoading: isLoadingLeakReports, isError: isErrorLeakReports } =
        useGetHistoricalLeakDataQuery({
            atg_name,
            year: selectedDate.getFullYear(),
            month: selectedDate.getMonth() + 1
        });

    const { data: reports, isLoading: isLoadingReports, isError: isErrorReports } = useGetAtgReportsByMonthQuery({
        atg_name,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1
    });

    const { data: meterReadings, isLoading } = useGetAtgMeterReadingsByAtgNameQuery({ atg_name });

    const {
        data: atgReturn
    } = useGetAtgQuery(atg_name, {
        pollingInterval: 30000
    })

    const atgAttributes = atgReturn?.attributes;


    // Extract tank configuration and product labels
    const tankConfig = configData?.shadow?.tank_config || {};
    const formatLabel = (label) => {
        if (!label) return '';
        return label
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // Memoize `productLabels` to prevent unnecessary re-renders
    const productLabels = useMemo(() => ({
        tank_1: formatLabel(tankConfig.tank_1?.product_label) || 'Tank 1',
        tank_2: formatLabel(tankConfig.tank_2?.product_label) || 'Tank 2',
        tank_3: formatLabel(tankConfig.tank_3?.product_label) || 'Tank 3',
        tank_4: formatLabel(tankConfig.tank_4?.product_label) || 'Tank 4',
    }), [tankConfig]);

    useEffect(() => {
        if (shiftStartTimeData !== undefined) {
            setShiftStartTime(shiftStartTimeData);
        }
    }, [shiftStartTimeData]);

    useEffect(() => {
        if (!isLoadingDeliveries && deliveryReports.length > 0) {
            const uniqueDeliveries = new Map();

            deliveryReports.forEach((report) => {
                const tanks = report?.state?.reported?.tanks || {};

                Object.keys(tanks).forEach((tankId) => {
                    const tankDeliveries = tanks[tankId]?.delivery_result || [];

                    tankDeliveries.forEach((delivery) => {
                        if (
                            delivery.start_timestamp &&
                            delivery.end_timestamp &&
                            delivery.starting_volume &&
                            delivery.ending_volume &&
                            delivery.delivery_volume
                        ) {
                            // Use a composite key to ensure uniqueness based on tank_id, start_timestamp, and end_timestamp
                            const key = `${tankId}-${delivery.start_timestamp}-${delivery.end_timestamp}`;

                            if (!uniqueDeliveries.has(key)) {
                                uniqueDeliveries.set(key, {
                                    id: key,
                                    tank_id: tankId,
                                    fuel_type: productLabels[tankId] || delivery.product_code || "Unknown",
                                    start_volume: delivery.starting_volume,
                                    end_volume: delivery.ending_volume,
                                    amount: delivery.delivery_volume,
                                    start_time: delivery.start_timestamp,
                                    end_time: delivery.end_timestamp,
                                });
                            }
                        }
                    });
                });
            });

            const processedDeliveries = Array.from(uniqueDeliveries.values());
            setDeliveries(processedDeliveries);
        }
    }, [isLoadingDeliveries, deliveryReports, productLabels]);

    console.log("Unmodified Leak Reports: ", leakReports)

    useEffect(() => {
        if (!isLoadingLeakReports && leakReports && leakReports.length > 0) {
            const uniqueLeakTests = new Map();

            leakReports.forEach((report) => {
                const tanks = report?.state?.reported?.tanks || {};

                Object.keys(tanks).forEach((tankId) => {
                    const leakResults = tanks[tankId]?.leak_result || [];

                    leakResults.forEach((test) => {
                        if (test.prev_test_start_timestamp && test.test_result_type) {
                            // Use a composite key to ensure uniqueness
                            const key = `${tankId}-${test.test_result_type}`;

                            if (!uniqueLeakTests.has(key)) {
                                // Map test_result_type to test type description and set leak thresholds
                                let testTypeDescription = '';
                                let leakThreshold = null;

                                switch (test.test_result_type) {
                                    case '00':
                                        testTypeDescription = '0.20 gal/hr test';
                                        leakThreshold = 0.20;
                                        break;
                                    case '01':
                                        testTypeDescription = '0.10 gal/hr test';
                                        leakThreshold = 0.10;
                                        break;
                                    case '02':
                                        testTypeDescription = 'Gross (3 gal/hr) test';
                                        leakThreshold = 3.00;
                                        break;
                                    default:
                                        testTypeDescription = 'Unknown Test Type';
                                        leakThreshold = null;
                                        break;
                                }

                                // Calculate the test result based on leak rate and threshold
                                let testResultDescription = 'Unknown Result';
                                if (leakThreshold !== null && test.test_rate !== undefined) {
                                    const leakRateAbs = Math.abs(test.test_rate);
                                    if (leakRateAbs <= leakThreshold) {
                                        testResultDescription = 'Test Passed';
                                    } else {
                                        testResultDescription = 'Test Failed';
                                    }
                                }

                                // Parse test.timestamp to a readable format
                                const testStartTime = test.prev_test_start_timestamp ? new Date(test.prev_test_start_timestamp) : null;
                                const formattedTestStartTime = testStartTime ? testStartTime.toLocaleString() : 'N/A';

                                // Adjust test_duration if needed
                                const testDuration = test.test_duration;

                                uniqueLeakTests.set(key, {
                                    id: key,
                                    tank_id: tankId,
                                    fuel_type: productLabels[tankId] || tankId,
                                    test_type: testTypeDescription,
                                    test_result: testResultDescription,
                                    test_start_time: formattedTestStartTime,
                                    test_duration: testDuration,
                                    test_volume: test.test_volume ? test.test_volume.toFixed(2) : 'N/A',
                                    leak_rate: test.test_rate ? test.test_rate.toFixed(4) : 'N/A',
                                });
                            }
                        }
                    });
                });
            });

            const processedLeakTests = Array.from(uniqueLeakTests.values());
            setLeakTests(processedLeakTests);
        }
    }, [isLoadingLeakReports, leakReports, productLabels]);


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    //function to handle the shift start time change
    const handleShiftStartTimeChange = (event) => {
        setShiftStartTime(event.target.value);
    };

    const handleSaveConfiguration = async () => {
        console.log("ATG: ", atg)
        try {
            await updateShiftStartTime({
                organization_id: atgAttributes.organization_id, // You need to replace this with the actual organization ID
                atg_name,
                shiftStartTime
            }).unwrap();
            console.log('Shift start time saved successfully');
        } catch (err) {
            console.error('Failed to save shift start time:', err);
        }
    };


    useEffect(() => {
        // Log to ensure this runs after data is fetched and logs the expected value
        if (shiftStartTimeData !== undefined) {
            setShiftStartTime(Number(shiftStartTimeData));
        }
    }, [shiftStartTimeData]);


    //console.log("Monthly Deliveries: ", deliveries)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: '100%' }}>
                {/* Shift Start Time Selection */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%', justifyContent: 'center' }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="shift-start-time-label">Shift Start Time</InputLabel>
                        <Select
                            labelId="shift-start-time-label"
                            id="shift-start-time"
                            value={shiftStartTime}
                            label="Shift Start Time"
                            onChange={handleShiftStartTimeChange}
                        >
                            {[...Array(24).keys()].map(hour => (
                                <MenuItem key={hour} value={hour}>{`${hour}:00`}</MenuItem> // Here `hour` is a number
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="outlined"
                        sx={{
                            mt: 1, // Adjust margin top as needed to align with the dropdown if necessary
                            bgcolor: 'rgba(0, 255, 0, 0.1)', // Opaque background
                            color: 'lightgreen', // Light green text
                            borderColor: 'lightgreen', // Light green border
                            '&:hover': {
                                bgcolor: 'rgba(0, 255, 0, 0.2)', // Slightly darker on hover
                                borderColor: 'green', // Darker green border on hover
                            },
                        }}
                        onClick={handleSaveConfiguration}
                    >
                        Save Configuration
                    </Button>
                </Box>

                {/* Top Row: StaticDatePicker and DailySummary */}
                <Grid container spacing={2} sx={{ mt: 1, width: '100%' }}>
                    <Grid item xs={12} md={12} lg={6}>
                        <ATGDateSelector
                            isLoading={isLoading}
                            selectedDate={selectedDate}
                            handleDateChange={handleDateChange}
                            meterReadings={meterReadings}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DailySummary
                            isReportsLoading={isLoadingReports}
                            isErrorReports={isErrorReports}
                            data={reports}
                            deliveries={deliveries}
                            productLabels={productLabels}
                            selectedDate={selectedDate}
                            atg_name={atg_name}
                            shiftStartTime={shiftStartTime}
                        />
                    </Grid>
                </Grid>

                {/* Bottom Row: DeliveryReports and LeakTestReports */}
                <Grid container spacing={2} sx={{ mt: 1, width: '100%' }}>
                    <Grid item xs={12} md={6}>
                        <DeliveryReports
                            deliveries={deliveries}
                            isLoading={isLoadingDeliveries}
                            isError={isErrorDeliveries}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LeakTestReports
                            leakTests={leakTests}
                            isLoading={isLoadingLeakReports}
                            isError={isErrorLeakReports}
                        />
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default InventoryTab;
