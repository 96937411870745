import React from 'react';
import {
    Badge,
    Box,
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    LinearProgress,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import {
    LocalGasStationOutlined,
    SpeedOutlined,
    LocalShippingOutlined,
    HelpOutline,
    Block as DisabledIcon,
} from '@mui/icons-material';
import Alarms from './components/Alarms';
import DeliveryLatest from './components/DeliveryLatest';
import { tokens } from '../../../../theme';

const formatTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
};

const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace(/(\d)(st|nd|rd|th)/, '$1$2');
};

const roundValue = (value) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return '';
    }
    return value % 1 === 0 ? value.toString() : value.toFixed(2);
};

const TankVolume = ({ volume, disabled }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const maxVolume = 12000;
    const filledPercentage = (volume / maxVolume) * 100;
    const labelPositions = {
        9000: 75,
        6000: 50,
        3000: 25,
    };

    // Adjust dimensions for mobile
    const tankHeight = disabled ? (isMobile ? '120px' : '150px') : (isMobile ? '200px' : '300px');
    const tankWidth = isMobile ? '90px' : '70px';

    const tankStyles = {
        height: tankHeight,
        width: tankWidth,
        borderRadius: '25px',
        border: '3px solid black',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: disabled ? '#ccc' : '#eee',
        marginRight: isMobile ? '10px' : '20px',
    };

    const volumeStyles = {
        height: `${filledPercentage}%`,
        width: tankWidth,
        position: 'absolute',
        bottom: '0',
        right: '0',
        backgroundColor: disabled ? '#999' : '#FFA500',
        borderRadius: '22px 22px 0 0',
    };

    const labelStyle = {
        position: 'absolute',
        left: '10px',
        transform: 'translateY(50%)',
        color: disabled ? '#999' : 'black',
        fontSize: isMobile ? '0.8rem' : '1rem',
    };

    return (
        <div style={tankStyles}>
            <div style={volumeStyles}></div>
            {Object.entries(labelPositions).map(([gallon, position]) => (
                <div key={gallon} style={{ ...labelStyle, bottom: `${position}%` }}>
                    <span>{gallon}</span>
                    <span style={{ display: 'inline-block', width: '15px', borderBottom: '1px solid black', marginLeft: '5px' }}></span>
                </div>
            ))}
            <div style={{ ...labelStyle, bottom: '5px' }}>0</div>
            <div style={{ ...labelStyle, top: '5px', transform: 'translateY(0%)' }}>{maxVolume}</div>
        </div>
    );
};

const TempBar = ({ temperature, disabled }) => {
    const maxTemp = 110;
    const tempPercentage = (temperature / maxTemp) * 100;
    const bgColor = `linear-gradient(to right, blue ${tempPercentage}%, red)`;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={isMobile ? 1 : 2}>
            <Typography sx={{ color: disabled ? '#999' : '#fff', fontSize: isMobile ? '0.8rem' : 'inherit' }}>
                Temp: {roundValue(temperature)}°F
            </Typography>
            <LinearProgress
                variant="determinate"
                value={tempPercentage}
                sx={{
                    height: '10px',
                    width: isMobile ? '100%' : '90%',
                    borderRadius: '5px',
                    background: disabled ? '#ccc' : '#eee',
                    mt: 1,
                    '& .MuiLinearProgress-bar': {
                        backgroundImage: disabled ? '#999' : bgColor,
                    },
                }}
            />
        </Box>
    );
};

// Map fuel types to their respective icons
const fuelTypeIcons = {
    unleaded: <LocalGasStationOutlined sx={{ fontSize: 35, color: '#fff' }} />,
    regular: <LocalGasStationOutlined sx={{ fontSize: 35, color: '#fff' }} />,
    premium: <SpeedOutlined sx={{ fontSize: 35, color: '#fff' }} />,
    super: <SpeedOutlined sx={{ fontSize: 35, color: '#fff' }} />,
    diesel: <LocalShippingOutlined sx={{ fontSize: 35, color: '#fff' }} />,
};

// Function to get the icon based on the fuel type
const getFuelTypeIcon = (fuelType) => {
    if (!fuelType) return <HelpOutline sx={{ fontSize: 35, color: '#fff' }} />;

    const lowerCaseFuel = fuelType.toLowerCase();
    const matchedType = Object.keys(fuelTypeIcons).find((key) => lowerCaseFuel.includes(key));

    return matchedType ? fuelTypeIcons[matchedType] : <HelpOutline sx={{ fontSize: 35, color: '#fff' }} />;
};

const TankInfoCard = ({ tankData, name, fuelType, disabled }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const formatFuelType = (fuelType) =>
        fuelType ? fuelType.charAt(0).toUpperCase() + fuelType.slice(1).toLowerCase() : '';

    const detailFontSize = isMobile ? '0.8rem' : '1.1rem'; // Slightly larger on non-mobile screens

    return (
        <Card
            elevation={3}
            sx={{
                backgroundColor: disabled ? '#666' : '#424242',
                padding: isMobile ? '10px' : '20px',
            }}
        >
            <CardContent sx={{ padding: '0 !important' }}>
                <Box display="flex" flexDirection="column" gap={isMobile ? 2 : 3}>
                    {/* Title and Fuel Info */}
                    {isMobile ? (
                        // On mobile: Tank X and Icon on the same line, Badge below
                        <>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography
                                    variant={disabled ? 'h6' : 'h5'}
                                    gutterBottom
                                    sx={{
                                        marginRight: 1,
                                        color: disabled ? '#999' : '#fff',
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                        fontSize: '1rem',
                                    }}
                                >
                                    {disabled ? 'Disabled' : name}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {disabled ? (
                                        <DisabledIcon sx={{ fontSize: 25, color: '#999' }} />
                                    ) : (
                                        getFuelTypeIcon(fuelType)
                                    )}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 5}}>
                                <Badge
                                    badgeContent={formatFuelType(fuelType)}
                                    color="primary"
                                    sx={{
                                        '.MuiBadge-badge': {
                                            fontSize: '1rem',
                                            height: 'auto',
                                            borderRadius: '12px',
                                            px: 1,
                                            backgroundColor: disabled ? '#999' : '#1976d2',
                                            color: disabled ? '#666' : '#fff',
                                        },
                                    }}
                                    overlap="rectangular"
                                />
                            </Box>
                        </>
                    ) : (
                        // On larger screens, keep Tank X, Badge, and Icon all on the same line
                        <Box 
                            display="flex" 
                            flexDirection="row" 
                            alignItems="center" 
                            gap={8} 
                            flexWrap="wrap"
                        >
                            <Typography
                                variant={disabled ? 'h5' : 'h3'}
                                gutterBottom
                                sx={{
                                    marginRight: 2,
                                    color: disabled ? '#999' : '#fff',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {disabled ? 'Disabled' : name}
                            </Typography>
                            <Badge
                                badgeContent={formatFuelType(fuelType)}
                                color="primary"
                                sx={{
                                    '.MuiBadge-badge': {
                                        fontSize: '1.5rem',
                                        height: 'auto',
                                        borderRadius: '12px',
                                        px: 1.5,
                                        backgroundColor: disabled ? '#999' : '#1976d2',
                                        color: disabled ? '#666' : '#fff',
                                    },
                                }}
                                overlap="rectangular"
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                {disabled ? (
                                    <DisabledIcon sx={{ fontSize: 35, color: '#999' }} />
                                ) : (
                                    getFuelTypeIcon(fuelType)
                                )}
                            </Box>
                        </Box>
                    )}

                    <Box
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        gap={isMobile ? 1 : 3}
                        alignItems="center"
                        justifyContent={isMobile ? 'center' : 'flex-start'}
                    >
                        <TankVolume volume={tankData.volume} disabled={disabled} />
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: disabled ? '#999' : '#fff',
                                    fontWeight: 'bold',
                                    fontSize: detailFontSize,
                                }}
                            >
                                Vol.: {roundValue(tankData.volume)} gal.
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: disabled ? '#999' : '#fff',
                                    fontWeight: 'bold',
                                    fontSize: detailFontSize,
                                }}
                            >
                                Ullage: {roundValue(tankData.ullage)}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: disabled ? '#999' : '#fff',
                                    fontWeight: 'bold',
                                    fontSize: detailFontSize,
                                }}
                            >
                                TC Vol.: {roundValue(tankData.tc_volume)}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: disabled ? '#999' : '#fff',
                                    fontWeight: 'bold',
                                    fontSize: detailFontSize,
                                }}
                            >
                                Water Vol.: {roundValue(tankData.water_volume)}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: disabled ? '#999' : '#fff',
                                    fontWeight: 'bold',
                                    fontSize: detailFontSize,
                                }}
                            >
                                Water Height: {roundValue(tankData.water_height)}
                            </Typography>
                            <TempBar temperature={tankData.temperature} disabled={disabled} />
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

const OverviewTab = ({ atg, atg_name, deliveries, config, leaks }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const fuelTypes = Object.keys(config.tank_config || {}).reduce((acc, tankKey) => {
        acc[tankKey] = config.tank_config[tankKey].product_label;
        return acc;
    }, {});

    const isNonZeroTank = (tankData) => {
        return tankData && Object.values(tankData).some((value) => value !== 0);
    };

    // Display a loading indicator while waiting for the config to load
    if (!config.tank_config) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6">Loading configuration...</Typography>
            </Box>
        );
    }

    return (
        <Paper elevation={2} sx={{ padding: isMobile ? '10px' : '20px' }}>
            <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ marginBottom: isMobile ? '10px' : '20px' }}>
                Local System Time: {formatTime(atg.system_time)}
            </Typography>
            <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ marginBottom: isMobile ? '10px' : '20px' }}>
                System Date: {formatDate(atg.system_date)}
            </Typography>
            <Grid container spacing={isMobile ? 2 : 3} sx={{ marginBottom: isMobile ? '10px' : '20px' }}>
                <Grid item xs={12} md={6}>
                    <Alarms atg_name={atg_name} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DeliveryLatest deliveries={deliveries} />
                </Grid>
            </Grid>

            <Grid container spacing={isMobile ? 2 : 3} justifyContent="space-evenly">
                {Object.keys(atg.tanks).map((tankKey, index) => {
                    const tankData = atg.tanks[tankKey].tank_data[0];
                    const tankConfig = config.tank_config[tankKey];
                    const isDisabled = tankConfig.config_flag_raw === '0';

                    return tankData && (isNonZeroTank(tankData) || isDisabled) ? (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <TankInfoCard
                                tankData={tankData}
                                name={`Tank ${tankKey.replace('tank_', '')}`}
                                fuelType={fuelTypes[tankKey]}
                                disabled={isDisabled}
                            />
                        </Grid>
                    ) : null;
                })}
            </Grid>
        </Paper>
    );
};

export default OverviewTab;
