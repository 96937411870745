import React from 'react';
import { Paper, useTheme, Box, CircularProgress, Typography, Badge } from '@mui/material';
import { StaticDatePicker, PickersDay } from '@mui/x-date-pickers';
import { tokens } from '../../../../../theme';

const CustomPickersDay = (props) => {
    const { day, selected, outsideCurrentMonth, ...other } = props;
    const meterReadings = props.meterReadings || [];
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Return an empty PickersDay for dates outside the current month
    if (outsideCurrentMonth) {
        return <PickersDay day={day} disabled />;
    }

    const isValidDate = day instanceof Date && !isNaN(day);

    const sameDate = (d1, d2) =>
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();

    const hasReadings = isValidDate && meterReadings.some(reading => {
        const readingDate = new Date(reading.metric_date);
        return sameDate(readingDate, day);
    });

    return (
        <Badge
            overlap="circular"
            badgeContent={hasReadings ? '🟢' : '🔴'}
            sx={{
                '& .MuiBadge-badge': {
                    top: '3px',
                    right: '20px',
                },
            }}
        >
            <PickersDay
                {...other}
                day={day}
                selected={selected}
                sx={{
                    typography: 'h6',
                    fontSize: '1.1rem',
                    ...(selected && {
                        bgcolor: 'primary.light',
                        color: 'white',
                        '&:hover': {
                            bgcolor: 'primary.dark',
                        },
                    }),
                }}
            />
        </Badge>
    );
};

const ATGDateSelector = ({ isLoading, selectedDate, handleDateChange, meterReadings }) => {
    return (
        <Paper
            style={{
                marginTop: '0px',
                padding: '10px',
                height: '500px', // Explicitly set the height
                display: 'flex', // Ensure content alignment
                flexDirection: 'column',
                justifyContent: 'space-between', // Align content evenly
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                sx={{ borderBottom: '1px solid #ddd', pb: 2 }}
            >
                <Typography variant="h4" gutterBottom>
                    Select a Date
                </Typography>
            </Box>
            <Box
                sx={{
                    padding: '10px',
                    marginBottom: '10px',
                    backgroundColor: 'primary.light',
                    borderRadius: '4px',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h4" color="white">
                    <strong>{`Selected Date: ${selectedDate.toLocaleDateString()}`}</strong>
                </Typography>
            </Box>

            <Box sx={{ height: '100%', width: '100%' }}>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                        <Typography variant="h4" sx={{ ml: 2 }}>
                            Loading Date Picker...
                        </Typography>
                    </Box>
                ) : (
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="day"
                        value={selectedDate}
                        onChange={handleDateChange}
                        slots={{
                            day: CustomPickersDay,
                        }}
                        slotProps={{
                            day: {
                                meterReadings: meterReadings,
                            },
                        }}
                    />
                )}
            </Box>
        </Paper>
    );
};

export default ATGDateSelector;
