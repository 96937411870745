import { Box, Typography, useTheme, useMediaQuery, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { useGetAuthorizedAtgsQuery } from "../../../../features/atgs/atgsApiSlice";
import PulseLoader from "react-spinners/PulseLoader";
import CircleIcon from "@mui/icons-material/Circle";

const ATGWidget = () => {
    const { username, role, organization_id } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const colors = tokens(theme.palette.mode);

    const {
        data: atgs,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetAuthorizedAtgsQuery({ role, organization_id });

    console.log("Authorized ATGS: ", atgs);

    // Define columns for desktop
    const desktopColumns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    color={colors.greenAccent[400]}
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "address",
            headerName: "Address",
            flex: 1,
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    color={colors.greenAccent[400]}
                    sx={{ cursor: "pointer" }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "organization_name",
            headerName: "Organization",
            width: 140,
            renderCell: (params) => (
                <Typography
                    variant="body2"
                    color={colors.greenAccent[100]}
                    onClick={() => navigate(`/console/organization/${params.row.organization_id}`)} // Navigate to organization
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                const isOnline = params.row.online;
                const lastSeen = params.row.last_update_received
                    ? Math.floor((new Date() - new Date(params.row.last_update_received)) / 60000)
                    : null;

                const displayLastSeen =
                    lastSeen > 3600
                        ? "Last seen long ago..."
                        : lastSeen !== null
                        ? `${lastSeen} min ago`
                        : "";

                return (
                    <Box display="flex" alignItems="center" gap={1} sx={{ cursor: "pointer" }}>
                        <CircleIcon sx={{ color: isOnline ? "green" : "red", fontSize: 12 }} />
                        <Typography
                            variant="body2"
                            color={isOnline ? colors.greenAccent[100] : colors.redAccent[100]}
                            sx={{ fontWeight: "bold" }}
                        >
                            {isOnline ? "Online" : "Offline"} {displayLastSeen && `(${displayLastSeen})`}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    // Define columns for mobile
    const mobileColumns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1.4,
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    color={colors.greenAccent[400]}
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "status",
            headerName: "Online",
            flex: .6,
            renderCell: (params) => {
                const isOnline = params.row.online;
                const lastSeen = params.row.last_update_received
                    ? Math.floor((new Date() - new Date(params.row.last_update_received)) / 60000)
                    : null;

                const displayLastSeen =
                    lastSeen > 3600
                        ? "Long ago"
                        : lastSeen !== null
                        ? `${lastSeen}m`
                        : "";

                return (
                    <Box display="flex" alignItems="center" gap={1} sx={{ cursor: "pointer" }}>
                        <CircleIcon sx={{ color: isOnline ? "green" : "red", fontSize: 12 }} />
                        <Typography
                            variant="body2"
                            color={isOnline ? colors.greenAccent[100] : colors.redAccent[100]}
                            sx={{ fontWeight: "bold" }}
                        >
                            {displayLastSeen}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    const columns = isMobile ? mobileColumns : desktopColumns;

    let content;

    if (isLoading) {
        content = <PulseLoader color={"#FFF"} />;
    } else if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>;
    } else if (isSuccess) {
        // Transform and sort ATGs by online status and recency
        const atgRows = atgs
            .map((atg) => ({
                ...atg,
                name: atg.attributes.name.replace(/_/g, " "),
                address: atg.attributes.address.replace(/_/g, " "),
            }))
            .sort((a, b) => {
                if (a.online && !b.online) return -1;
                if (!a.online && b.online) return 1;

                const aLastSeen = new Date(a.last_update_received).getTime() || 0;
                const bLastSeen = new Date(b.last_update_received).getTime() || 0;
                return bLastSeen - aLastSeen; // Sort by most recent last_update_received
            });

        content = (
            <Paper sx={{ height: "100%", p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    <Link to={`/console/atgs`}>{`Available ATGs:`}</Link>
                </Typography>
                <Box
                    width="100%"
                    height="50vh"
                    sx={{
                        "& .MuiDataGrid-root": { border: "none" },
                        "& .MuiDataGrid-cell": { borderBottom: "none" },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                    }}
                >
                    <DataGrid
                        rows={atgRows}
                        columns={columns}
                        onCellClick={(params) => {
                            if (params.field !== "organization_name") {
                                navigate(`/console/atgs/${params.row.thingName}`);
                            }
                        }}
                    />
                </Box>
            </Paper>
        );
    }

    return content;
};

export default ATGWidget;
