import React from 'react';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const LeakTestReports = ({ leakTests, isLoading, isError }) => {
    const columns = [
        { field: 'fuel_type', headerName: 'Fuel Type', width: 120 },
        { field: 'test_type', headerName: 'Test Type', width: 160 },
        { field: 'test_result', headerName: 'Test Result', width: 180 },
        { field: 'test_start_time', headerName: 'Test Start Time', width: 180 },
        { field: 'test_duration', headerName: 'Duration (hrs)', width: 130 },
        { field: 'test_volume', headerName: 'Test Volume', width: 130 },
        { field: 'leak_rate', headerName: 'Leak Rate', width: 130 },
    ];

    console.log('leakTests:', leakTests);

    let content;

    if (isLoading) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
                <Typography variant="h4" sx={{ ml: 2 }}>
                    Loading leak tests...
                </Typography>
            </Box>
        );
    } else if (isError) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="body1" color="error">
                    Error loading leak tests. Please try again later.
                </Typography>
            </Box>
        );
    } else if (leakTests.length === 0) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="body1">No leak tests found.</Typography>
            </Box>
        );
    } else {
        content = (
            <DataGrid
                rows={leakTests}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
            />
        );
    }

    return (
        <Paper style={{ marginTop: '0px', padding: '10px' }}>
            <Typography variant="h4" gutterBottom style={{ marginBottom: '20px' }}>
                Leak Test Reports
            </Typography>
            <Box sx={{ height: 400, width: '100%' }}>{content}</Box>
        </Paper>
    );
};

export default LeakTestReports;
