import React from 'react';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const DeliveryReports = ({ deliveries, isLoading, isError }) => {
    const columns = [
        { field: 'fuel_type', headerName: 'Fuel Type', width: 120 },
        { field: 'start_volume', headerName: 'Start', width: 70 },
        { field: 'end_volume', headerName: 'End', width: 70 },
        { field: 'amount', headerName: 'Delivered', width: 70 },
        { field: 'start_time', headerName: 'Start Time', width: 140 },
        { field: 'end_time', headerName: 'End Time', width: 140 },
    ];

    let content;

    if (isLoading) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
                <Typography variant="h4" sx={{ ml: 2 }}>
                    Loading deliveries...
                </Typography>
            </Box>
        );
    } else if (isError) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="body1" color="error">
                    Error loading deliveries. Please try again later.
                </Typography>
            </Box>
        );
    } else if (deliveries.length === 0) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="body1">No deliveries found.</Typography>
            </Box>
        );
    } else {
        content = (
            <DataGrid
                rows={deliveries}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
            />
        );
    }

    return (
        <Paper style={{ marginTop: '0px', padding: '10px' }}>
            <Typography variant="h4" gutterBottom style={{ marginBottom: '20px' }}>
                Deliveries
            </Typography>
            <Box sx={{ height: 400, width: '100%' }}>{content}</Box>
        </Paper>
    );
};

export default DeliveryReports;
