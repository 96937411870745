import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    useTheme,
    Grid,
    Paper,
    TextField,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Box,
    FormControlLabel,
    Checkbox,
    Radio,
    MenuItem,
    Select,
    IconButton,
} from "@mui/material";

import {
    DeleteOutline,
    AddOutlined
} from "@mui/icons-material";

import { tokens } from '../../../../../theme';

import { useUpdateUserNotificationPreferencesMutation } from '../../../../../features/users/usersApiSlice';
import { useGetUserByUsernameQuery } from '../../../../../features/users/usersApiSlice';
import useAuth from '../../../../../hooks/useAuth';


const NotificationSettings = ({ atg, atg_name }) => {
    const [updateUserNotifications] = useUpdateUserNotificationPreferencesMutation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dailySummaryEmail, setDailySummaryEmail] = useState(false);
    const [tankAlarmConfigs, setTankAlarmConfigs] = useState([{ type: 'volume', threshold: '', methods: { email: true, sms: false } }]);
    const validTankNames = Object.keys(atg)
        .filter(tankName => tankName.startsWith('t') && atg[tankName].volume > 0);


    const handleAddTankAlarm = () => {
        setTankAlarmConfigs(prev => [...prev, { type: 'volume', threshold: '', methods: { email: true, sms: false } }]);
    };
    const handleRemoveTankAlarm = (index) => {
        setTankAlarmConfigs(prev => prev.filter((_, i) => i !== index));
    };

    const handleSaveNotificationSettings = async () => {
        let updatedNotificationPreferences = [...user.notificationPreferences];

        // Remove the old daily_readout and tank_alarm preferences for this atg_name
        updatedNotificationPreferences = updatedNotificationPreferences.filter(pref =>
            !(pref.atg_name === atg_name && (pref.type === 'daily_readout' || pref.type === 'tank_alarm'))
        );

        // Add/Update the daily_readout preference
        if (dailySummaryEmail) {
            updatedNotificationPreferences.push({
                type: 'daily_readout',
                message: 'Daily summary email notification.',
                methods: ['email'],
                atg_name: atg_name
            });
        }

        // Add/Update the tank_alarm preferences
        const newTankAlarmPreferences = tankAlarmConfigs.map(config => ({
            type: 'tank_alarm',
            message: `Tank alarm set for ${config.type} with threshold of ${config.threshold}.`,
            methods: Object.keys(config.methods).filter(method => config.methods[method]),
            tankIdentifier: config.tankIdentifier,
            atg_name: atg_name,
            subType: config.type,
            threshold: Number(config.threshold),
            ruleName: config.ruleName,
            _id: config._id
        }));

        updatedNotificationPreferences = [...updatedNotificationPreferences, ...newTankAlarmPreferences];

        const notificationsData = {
            username: username,
            notificationPreferences: updatedNotificationPreferences
        };

        await updateUserNotifications(notificationsData);
    };

    const { username, isManager, isAdmin } = useAuth()


    const { data: user, isLoading: isUserLoading, isError: isUserError, error: Usererror } = useGetUserByUsernameQuery(username, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });
    useEffect(() => {
        if (user && user.notificationPreferences) {
            const dailySummaryNotification = user.notificationPreferences.find(
                (n) => n.type === 'daily_readout' && n.atg_name === atg_name
            );

            console.log(atg_name)

            console.log("Daily Email Preference: ", user.notificationPreferences, dailySummaryNotification)

            if (dailySummaryNotification) {
                setDailySummaryEmail(true);
            } else {
                setDailySummaryEmail(false); // Ensure it is set to false if no matching preference is found
            }

            const extractedNotificationPreferences = user.notificationPreferences
                .filter(notification =>
                    notification.type === 'tank_alarm' && notification.atg_name === atg_name
                )
                .map(notification => ({
                    type: notification.subType,
                    threshold: notification.threshold,
                    methods: {
                        email: notification.methods.includes('email'),
                        sms: notification.methods.includes('sms')
                    },
                    tankIdentifier: notification.tankIdentifier,
                    ruleName: notification.ruleName,  // Extract ruleName here
                    _id: notification._id
                }));

            setTankAlarmConfigs(extractedNotificationPreferences);
        }
    }, [user]);



    return (
        <Grid item xs={12}>
            <Paper elevation={2} style={{ padding: '20px' }}>
                <Typography variant="h4" color={colors.greenAccent[300]}>
                    Notification Settings
                </Typography>

                <Box mt={2}>
                    <FormControlLabel
                        control={<Checkbox checked={dailySummaryEmail} onChange={e => setDailySummaryEmail(e.target.checked)} />}
                        label="Daily Summary Email?"
                    />
                </Box>

                <Typography variant="h6" color={colors.greenAccent[300]} mt={3}>
                    Tank Alarms
                </Typography>
                {tankAlarmConfigs.map((config, index) => (
                    <Grid container spacing={2} alignItems="center" key={index}>
                        <Grid item>
                            <Select
                                value={config.type}
                                onChange={e => {
                                    const newConfig = [...tankAlarmConfigs];
                                    newConfig[index].type = e.target.value;
                                    setTankAlarmConfigs(newConfig);
                                }}
                            >
                                <MenuItem value={'volume'}>Volume</MenuItem>
                                <MenuItem value={'height'}>Height</MenuItem>
                                <MenuItem value={'water_volume'}>Water Volume</MenuItem>
                                <MenuItem value={'temp'}>Temperature</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <Select
                                value={config.tankIdentifier || ''}
                                onChange={e => {
                                    const newConfig = [...tankAlarmConfigs];
                                    newConfig[index].tankIdentifier = e.target.value;
                                    setTankAlarmConfigs(newConfig);
                                }}
                            >
                                {validTankNames.map(tank => (
                                    <MenuItem key={tank} value={tank}>{tank.toUpperCase()}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={`Threshold`}
                                variant="outlined"
                                value={config.threshold}
                                onChange={e => {
                                    const newConfig = [...tankAlarmConfigs];
                                    newConfig[index].threshold = e.target.value;
                                    setTankAlarmConfigs(newConfig);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={config.methods.email}
                                        onChange={() => {
                                            const newConfig = [...tankAlarmConfigs];
                                            newConfig[index].methods.email = !config.methods.email;
                                            setTankAlarmConfigs(newConfig);
                                        }}
                                    />
                                }
                                label="Email"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={config.methods.sms}
                                        onChange={() => {
                                            const newConfig = [...tankAlarmConfigs];
                                            newConfig[index].methods.sms = !config.methods.sms;
                                            setTankAlarmConfigs(newConfig);
                                        }}
                                    />
                                }
                                label="SMS"
                            />
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => handleRemoveTankAlarm(index)}>
                                <DeleteOutline />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Box mt={2}>
                    <Button startIcon={<AddOutlined />} variant="contained" color="primary" onClick={handleAddTankAlarm}>
                        Add Alarm
                    </Button>
                </Box>

                <Box mt={3}>
                    <Button variant="contained" color="primary" onClick={handleSaveNotificationSettings}>
                        Save Changes
                    </Button>
                </Box>
            </Paper>
        </Grid>
    )
}

export default NotificationSettings

