import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Typography, Box, CircularProgress, useMediaQuery } from "@mui/material";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useGetRecentAtgQuery, useGetAtgReportsQuery, useGetAtgQuery, useGetATGConfigDataQuery } from '../../../../features/atgs/atgsApiSlice';
import useAuth from '../../../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useGetOrganizationByIdQuery } from '../../../../features/organizations/organizationsApiSlice';

const ChartWidget = () => {
    const { username, role, organization_id } = useAuth();
    const isMobile = useMediaQuery('(max-width:600px)'); // Detect mobile screens
    const [range, setRange] = useState('day');

    const { data: org_query, isLoading: isOrgLoading } = useGetOrganizationByIdQuery(organization_id);
    const organization = org_query?.entities?.[organization_id];
    const organization_type = organization?.type;

    const { data: recentAtg, isLoading: isRecentLoading } = useGetRecentAtgQuery({
        role,
        organization_id,
        organization_type
    });
    const atg_name = recentAtg?.atg_name;

    const { data: atgReturn, isLoading: isAtgLoading, isError: isAtgError, error: Atgerror } = useGetAtgQuery(atg_name, { skip: !atg_name });
    const atgAttributes = atgReturn?.attributes;
    const chartTitle = atgAttributes?.name.replace(/_/g, ' ') || atg_name || 'Loading...';

    const { data: configData, isLoading: isConfigLoading } = useGetATGConfigDataQuery(atg_name, { skip: !atg_name });
    const tankConfig = configData?.shadow.tank_config || {};

    const { data: reports, isLoading: isReportsLoading, refetch } = useGetAtgReportsQuery({ atg_name, range }, { skip: !atg_name });

    useEffect(() => {
        if (atg_name) {
            refetch();
        }
    }, [atg_name, refetch]);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (reports) {
            const sortedReports = [...reports].sort((a, b) => {
                const dateA = new Date(`${a.state.reported.system_date}T${a.state.reported.system_time}`);
                const dateB = new Date(`${b.state.reported.system_date}T${b.state.reported.system_time}`);
                return dateA - dateB;
            });

            const filteredReports = sortedReports.filter((_, index) => index % 3 === 0);
            const transformedData = filteredReports.map((report) => {
                const { system_date, system_time, tanks } = report.state.reported;
                return {
                    time: `${system_date} ${system_time}`,
                    volumeT1: tanks.tank_1?.tank_data[0]?.volume || 0,
                    volumeT2: tanks.tank_2?.tank_data[0]?.volume || 0,
                    volumeT3: tanks.tank_3?.tank_data[0]?.volume || 0,
                    volumeT4: tanks.tank_4?.tank_data[0]?.volume || 0,
                };
            });
            setData(transformedData);
        }
    }, [reports]);

    const formatLabel = (label) => label?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '';
    const productLabels = {
        tank_1: formatLabel(tankConfig.tank_1?.product_label) || 'Tank 1',
        tank_2: formatLabel(tankConfig.tank_2?.product_label) || 'Tank 2',
        tank_3: formatLabel(tankConfig.tank_3?.product_label) || 'Tank 3',
        tank_4: formatLabel(tankConfig.tank_4?.product_label) || 'Tank 4',
    };

    const currentVolumes = {
        volumeT1: atgReturn?.shadow?.t1?.volume || 0,
        volumeT2: atgReturn?.shadow?.t2?.volume || 0,
        volumeT3: atgReturn?.shadow?.t3?.volume || 0,
        volumeT4: atgReturn?.shadow?.t4?.volume || 0,
    };

    const nonZeroTanks = Object.keys(currentVolumes).filter((key) => data.some((entry) => entry[key] > 0));

    // Custom Tooltip
    const CustomTooltip = useCallback(({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);

            return (
                <div style={{
                    backgroundColor: '#333',
                    padding: '10px',
                    borderRadius: '5px',
                    color: '#fff',
                    boxShadow: '0 0 5px rgba(0,0,0,0.5)',
                }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{date.toLocaleString()}</p>
                    {payload.map((entry, index) => {
                        const unit = entry.name.toLowerCase().includes('volume') ? '(gal.)' : '';
                        return (
                            <p key={`item-${index}`} style={{ margin: 0 }}>
                                <span style={{ color: entry.stroke }}><strong>{entry.name}</strong></span>: {entry.value} {unit}
                            </p>
                        );
                    })}
                </div>
            );
        }
        return null;
    }, []);

    if (isAtgLoading || isConfigLoading || isRecentLoading) {
        return (
            <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Paper>
        );
    }

    if (isAtgError) {
        return (
            <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" color="error">
                    Failed to load ATG data: {Atgerror.message}
                </Typography>
            </Paper>
        );
    }

    return (
        <Paper sx={{ p: isMobile ? 1 : 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>
                <Link to={`/console/atgs/${atg_name}`}>{`${chartTitle} ${range === 'day' ? 'Daily' : 'Weekly'} Volume Report`}</Link>
            </Typography>
            <Box sx={{ flexGrow: 1, height: isMobile ? '300px' : '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" tick={{ fontSize: isMobile ? 10 : 14 }} />
                        <YAxis tick={{ fontSize: isMobile ? 10 : 14 }} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend wrapperStyle={{ fontSize: isMobile ? '10px' : '14px' }} />
                        {nonZeroTanks.includes('volumeT1') && (
                            <Line type="monotone" dataKey="volumeT1" stroke="#32CD32" name={productLabels.tank_1} />
                        )}
                        {nonZeroTanks.includes('volumeT2') && (
                            <Line type="monotone" dataKey="volumeT2" stroke="#82ca9d" name={productLabels.tank_2} />
                        )}
                        {nonZeroTanks.includes('volumeT3') && (
                            <Line type="monotone" dataKey="volumeT3" stroke="#8884d8" name={productLabels.tank_3} />
                        )}
                        {nonZeroTanks.includes('volumeT4') && (
                            <Line type="monotone" dataKey="volumeT4" stroke="#FF4500" name={productLabels.tank_4} />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </Box>
            <Box sx={{ display: isMobile ? 'block' : 'flex', justifyContent: 'space-around', mt: 2 }}>
                {Object.entries(currentVolumes).map(([key, volume]) => (
                    volume > 0 && (
                        <Typography key={key} variant="body2">
                            {productLabels[key.replace('volumeT', 'tank_')]}: {volume} gallons
                        </Typography>
                    )
                ))}
            </Box>
        </Paper>
    );
};

export default ChartWidget;
