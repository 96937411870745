import React from 'react';
import Header from '../global/Header';
import { Box, Grid } from '@mui/material';
import ATGWidget from './widgets/ATGWidget';
import ChartWidget from './widgets/ChartWidget';

const Dashboard = () => {
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to the US Fuel Pro Console." />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <ATGWidget />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <ChartWidget />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
