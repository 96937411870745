import React, { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    Paper,
    Switch,
    TextField,
    Box,
    Button,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel
} from "@mui/material";
import { CheckCircle, Cancel, WaterSharp } from '@mui/icons-material';
import { tokens } from '../../../../../theme';
import { useStartStopCSLDTestMutation, useGetATGLeakDataQuery, useSetATGJobScheduleMutation, useGetCSLDScheduleQuery } from '../../../../../features/atgs/atgsApiSlice';


const USFuelCSLDControls = ({ atg_name }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [duration, setDuration] = useState(6);
    const [startTime, setStartTime] = useState('00:00');
    const [showScheduleSection, setShowScheduleSection] = useState(true); // Default to true
    const [csldEnabled, setCsldEnabled] = useState(false);
    const [runningTanks, setRunningTanks] = useState(0);
    const [selectedTankInfo, setSelectedTankInfo] = useState({});
    const [scheduleExists, setScheduleExists] = useState(false); // Tracks if a schedule exists
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Track dialog open state
    const [successDialogOpen, setSuccessDialogOpen] = useState(false); // Track success dialog open state
    const [isInitialLoad, setIsInitialLoad] = useState(true); // New state to track initial load


    const [startStopCSLDTest, { isLoading: isToggleLoading, isError: isToggleError }] = useStartStopCSLDTestMutation();
    const [setATGJobSchedule, { isLoading: isScheduleLoading, isError: isScheduleError }] = useSetATGJobScheduleMutation();
    const { data: leakData, isLoading: isLeakLoading } = useGetATGLeakDataQuery(atg_name, {});
    const { data: scheduleData, isLoading: isScheduleLoadingQuery } = useGetCSLDScheduleQuery(atg_name);

    // Convert a UTC cron expression to local time
    const cronToLocalTime = (cronExpression) => {
        const [minute, hour] = cronExpression.match(/\d+/g).slice(0, 2);
        const utcDate = new Date();
        utcDate.setUTCHours(parseInt(hour, 10), parseInt(minute, 10), 0);

        // Convert UTC to local time
        const localDate = new Date(utcDate.toLocaleString("en-US", { timeZone: userTimeZone }));
        const localHours = localDate.getHours().toString().padStart(2, "0");
        const localMinutes = localDate.getMinutes().toString().padStart(2, "0");
        return `${localHours}:${localMinutes}`; // Format to 'HH:mm'
    };

    // Convert local time to a UTC cron expression
    const localTimeToCron = (localTime) => {
        const [hours, minutes] = localTime.split(":").map(Number);
        const localDate = new Date();
        localDate.setHours(hours, minutes, 0, 0);

        // Convert local to UTC
        const utcDate = new Date(localDate.toISOString()); // Convert local date to UTC
        const utcHours = utcDate.getUTCHours();
        const utcMinutes = utcDate.getUTCMinutes();

        return `cron(${utcMinutes} ${utcHours} * * ? *)`;
    };

    const formatTimeWithDetails = (time24h) => {
        const [hour24, minute] = time24h.split(':').map(Number);

        // Convert to 12-hour format
        const hour12 = hour24 % 12 || 12;
        const amPm = hour24 < 12 ? 'AM' : 'PM';

        // Determine if it's noon or midnight
        let specialNote = '';
        if (hour24 === 0 && minute === 0) {
            specialNote = ' (midnight)';
        } else if (hour24 === 12 && minute === 0) {
            specialNote = ' (noon)';
        }

        // Return combined 24-hour and 12-hour format with special note
        return `${time24h} / ${hour12}:${minute.toString().padStart(2, '0')} ${amPm}${specialNote}`;
    };


    // Load existing schedule on mount
    useEffect(() => {
        if (!isScheduleLoadingQuery) {
            if (scheduleData?.scheduleExists) {
                const cronExpression = scheduleData.scheduleDetails.cronExpression;
                const duration = scheduleData.scheduleDetails.duration;

                // Convert cron expression to local time and set duration
                const localTime = cronToLocalTime(cronExpression);
                setStartTime(localTime);
                setDuration(duration);
                setScheduleExists(true);
            } else {
                setScheduleExists(false);
            }
            setIsInitialLoad(false); // Set initial load to false once data is ready
        }
    }, [scheduleData, isScheduleLoadingQuery]);

    useEffect(() => {
        if (leakData) {
            const tanks = leakData?.shadow?.tanks || {};
            let activeTanks = 0;
            let firstRunningTank = null;
            const currentTime = new Date();

            Object.values(tanks).forEach(tank => {
                const csldResult = tank?.usfuel_csld_result || {};
                if (csldResult.status === "Running") {
                    const resultTimestamp = new Date(csldResult.timestamp);
                    const timeDiff = (currentTime - resultTimestamp) / (1000 * 60);
                    if (timeDiff <= 2) {
                        activeTanks += 1;
                        if (!firstRunningTank) {
                            firstRunningTank = csldResult;
                        }
                    }
                }
            });

            setRunningTanks(activeTanks);
            setCsldEnabled(activeTanks > 0);
            setSelectedTankInfo(firstRunningTank || {});
        }
    }, [leakData]);

    const openConfirmDialog = () => setConfirmDialogOpen(true);
    const closeConfirmDialog = () => setConfirmDialogOpen(false);

    const openSuccessDialog = () => setSuccessDialogOpen(true);
    const closeSuccessDialog = () => setSuccessDialogOpen(false);

    const handleCsldToggle = async () => {
        const action = csldEnabled ? "stop" : "start";
        setCsldEnabled(!csldEnabled);

        try {
            await startStopCSLDTest({ atg_name, action }).unwrap();
            console.log(`CSLD test ${action} triggered successfully.`);
        } catch (error) {
            console.error("Failed to toggle CSLD test:", error);
            setCsldEnabled(csldEnabled);
        }
    };

    const handleSaveSettings = async () => {
        if (duration < 6 || duration > 24) {
            alert("Please set a duration between 6 and 24 hours.");
            return;
        }

        const cronExpression = localTimeToCron(startTime);

        try {
            await setATGJobSchedule({
                atg_name,
                jobType: "startCSLDTest",
                action: "start",
                cronExpression,
                duration
            }).unwrap();
            setScheduleExists(true);
            openSuccessDialog(); // Open success dialog
            console.log(`CSLD test scheduled successfully with cron: ${cronExpression}`);
        } catch (error) {
            console.error("Failed to schedule CSLD test:", error);
        }
    };

    const handleCancelSchedule = async () => {
        try {
            await setATGJobSchedule({
                atg_name,
                jobType: "cancelCSLDTest",
                action: "cancel",
            }).unwrap();
            setScheduleExists(false);
            setStartTime("00:00");
            setDuration(6);
            closeConfirmDialog();
            console.log("CSLD test schedule canceled successfully.");
        } catch (error) {
            console.error("Failed to cancel schedule:", error);
        }
    };

    return (
        <Paper
            elevation={3}
            style={{
                padding: '20px',
                background: `linear-gradient(to bottom, ${colors.primary[400]}, ${colors.primary[600]})`
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center" gap={1}>
                    <WaterSharp style={{ color: colors.greenAccent[300] }} />
                    <Typography variant="h4" style={{ color: colors.greenAccent[300] }}>
                        USFuel CSLD Controls
                    </Typography>
                </Box>

                <Paper elevation={1} style={{
                    padding: '10px 15px',
                    background: colors.primary[700],
                    minWidth: '200px',
                }}>
                    <Box display="flex" alignItems="center" gap={1}>
                        {csldEnabled ?
                            <CheckCircle style={{ color: 'green' }} /> :
                            <Cancel style={{ color: 'red' }} />}
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            CSLD Status
                        </Typography>
                    </Box>
                    <Typography variant="body2">Status: {csldEnabled ? "Running" : "Stopped"}</Typography>
                    <Typography variant="body2">Tanks Running: {runningTanks}</Typography>
                    <Typography variant="body2">
                        Elapsed Time: {selectedTankInfo.current_test_time_elapsed || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                        Last Run: {selectedTankInfo.timestamp || "N/A"}
                    </Typography>
                </Paper>
            </Box>

            <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                    Manual Control (Triggers Default 12hr Test)
                </Typography>
                <Typography variant="body1">CSLD On/Off</Typography>
                <Switch
                    checked={csldEnabled}
                    onChange={handleCsldToggle}
                    color="primary"
                    disabled={isToggleLoading || isLeakLoading}
                    sx={{
                        transform: 'scale(1.5)',
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: 'green',
                        },
                        '& .MuiSwitch-switchBase': {
                            color: colors.primary[200],
                        },
                        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                            backgroundColor: 'red',
                        },
                    }}
                />
                {isToggleError && <Typography color="error">Failed to toggle CSLD.</Typography>}
            </Box>



            {isInitialLoad ? (
                <Box mb={3}>
                    <Typography variant="body1" style={{ color: colors.primary[100]}}>
                        Loading schedule...
                    </Typography>
                </Box>
            ) : !scheduleExists ? (
                <Box mb={3}>
                    <Typography variant="body1" style={{ color: colors.redAccent[300]}}>
                        No schedule exists. Default values are loaded.
                    </Typography>
                </Box>
            ) : null}

            {showScheduleSection && (
                <Box mb={3}>
                    <Typography variant="h6" gutterBottom>
                        Scheduling
                    </Typography>
                    <Typography variant="body2" style={{ color: colors.primary[200], marginBottom: '10px' }}>
                        Configure the test to run daily at the specified start time for the set duration (between 6 and 24 hours).
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Duration (hrs)"
                                type="number"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Start Time"
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}

            <Box display="flex" justifyContent="space-between">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveSettings}
                    disabled={isScheduleLoading}
                >
                    Save Schedule
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={openConfirmDialog}
                    disabled={isScheduleLoading}
                >
                    Cancel Schedule
                </Button>
            </Box>

            {/* Confirmation Dialog */}
            <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
                <DialogTitle>
                    <Typography variant="h3" style={{ color: colors.primary[200] }}>
                        Cancel Schedule
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h4" style={{ color: colors.primary[100] }}>
                            Are you sure you want to cancel the CSLD schedule?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeConfirmDialog}
                        style={{
                            backgroundColor: colors.primary[500],
                            color: colors.primary[100],
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={handleCancelSchedule}
                        style={{
                            backgroundColor: colors.redAccent[700],
                            color: colors.primary[100],
                        }}
                        autoFocus
                    >
                        Yes, Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={successDialogOpen} onClose={closeSuccessDialog}>
                <DialogTitle>
                    <Typography variant="h2" style={{ color: colors.primary[200] }}>
                        Schedule Saved
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h4" style={{ color: colors.primary[100] }}>
                            Your CSLD test has been scheduled successfully.
                        </Typography>
                        {/* Format time in 24-hour and 12-hour formats */}
                        <Typography variant="h4" style={{ marginTop: '10px', color: colors.primary[100] }}>
                            <strong>Start Time:</strong> {formatTimeWithDetails(startTime)} ({userTimeZone})
                        </Typography>
                        <Typography variant="h4" style={{ color: colors.primary[100] }}>
                            <strong>Duration:</strong> {duration} hours
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeSuccessDialog}
                        style={{
                            backgroundColor: colors.primary[500],
                            color: colors.primary[100],
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </Paper>
    );
};

export default USFuelCSLDControls;