import React, { useState, useMemo, useEffect } from 'react';
import { Grid, Paper, Typography, TextField, Box, Button, CircularProgress, useTheme, Tooltip } from '@mui/material';
import { useUpdateAtgMeterReadingsMutation } from '../../../../../features/organizations/organizationsApiSlice';
import { useGetAtgQuery } from '../../../../../features/atgs/atgsApiSlice';
import { useGetAtgMeterReadingsByAtgNameQuery } from '../../../../../features/organizations/organizationsApiSlice';
import generateSIRPDF from '../components/SIRPDFReport';
import generateLeakTestPDF from '../components/LeakTestPDFReport';
import usfuelblack from '../../../../../assets/usfuelblack.png';
import LeakTestReports from '../components/LeakTestReports';
import { tokens } from '../../../../../theme';

const DailySummary = ({ isReportsLoading, data, isErrorReports, deliveries, productLabels, selectedDate, atg_name, shiftStartTime }) => {
    const [meterReadings, setMeterReadings] = useState({});
    const [updateAtgMeterReadings] = useUpdateAtgMeterReadingsMutation();
    const { data: fetchedMeterReadings, isLoading, isError } = useGetAtgMeterReadingsByAtgNameQuery({ atg_name });
    const [logoImg, setLogoImg] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (!isLoading && fetchedMeterReadings) {
            const selectedDateString = selectedDate.toISOString().split('T')[0];
            const readingsForSelectedDate = fetchedMeterReadings.find(reading =>
                new Date(reading.metric_date).toISOString().split('T')[0] === selectedDateString
            );

            if (readingsForSelectedDate) {
                const newMeterReadings = readingsForSelectedDate.fuel_readings.reduce((acc, curr) => {
                    acc[curr.fuel_type] = curr.amount_dispensed;
                    return acc;
                }, {});
                setMeterReadings(newMeterReadings);
            } else {
                setMeterReadings({}); // Reset if no readings for selected date
            }
        }
    }, [selectedDate, fetchedMeterReadings, isLoading]);

    // Fetch the logo PNG
    useEffect(() => {
        fetch(usfuelblack)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setLogoImg(base64data);
                }
            });
    }, []);

    const {
        data: atgReturn
    } = useGetAtgQuery(atg_name, {
        pollingInterval: 30000,
    })

    useEffect(() => {
        if (!isLoading && meterReadings) {
        }
    }, [meterReadings, isLoading, atg_name]);

    const atgAttributes = atgReturn?.attributes;

    const organization_id = atgAttributes?.organization_id

    const selectedDateString = selectedDate.toISOString().split('T')[0];

    const tanksData = useMemo(() => {
        if (!data || isReportsLoading) return [];
        const tanks = [];

        data.forEach((report) => {
            const reportTanks = report?.state?.reported?.tanks || {};
            Object.entries(reportTanks).forEach(([tankKey, tankDetails]) => {
                const tankRecords = tankDetails?.tank_data || [];
                tankRecords.forEach((tankRecord) => {
                    tanks.push({
                        ...tankRecord,
                        tankKey,
                        reportDate: report.state.reported.system_date,
                        reportTime: report.state.reported.system_time,
                    });
                });
            });
        });

        return tanks.filter((tank) => {
            if (!tank.timestamp) return false;
            const tankDate = new Date(tank.timestamp);
            return tankDate.toISOString().split('T')[0] === selectedDateString;
        });
    }, [data, selectedDate, isReportsLoading]);

    const deliveriesForDay = useMemo(() => {
        const startOfDay = new Date(selectedDate).setHours(0, 0, 0, 0);
        const endOfDay = new Date(selectedDate).setHours(23, 59, 59, 999);

        return deliveries.filter((delivery) => {
            const deliveryEndTime = new Date(delivery.end_time).getTime();
            return deliveryEndTime >= startOfDay && deliveryEndTime <= endOfDay;
        });
    }, [deliveries, selectedDate]);

    const calculateOversShorts = (tankKey, meterReading) => {
        const tankRecords = tanksData.filter((tank) => tank.tankKey === tankKey);
        const sortedRecords = tankRecords.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        const startRecord = sortedRecords[0];
        const endRecord = sortedRecords[sortedRecords.length - 1];

        const startVolume = startRecord?.volume || 0;
        const endVolume = endRecord?.volume || 0;
        const volumeDiff = startVolume - endVolume;

        const deliveryForTank = deliveriesForDay.find((delivery) => delivery.tank_id === tankKey);
        const deliveryVolume = deliveryForTank?.amount || 0;

        const oversShorts = volumeDiff + deliveryVolume - meterReading;

        return { startVolume, endVolume, deliveryVolume, volumeDiff, oversShorts };
    };

    const leakTestReports = data?.filter(
        (report) => !report?.state?.reported?.system_time?.trim()
    );

    // Only calculate data when reports are loaded
    const [filteredData, nextDayFirstRecord, uniqueDeliveries] = useMemo(() => {
        if (isReportsLoading || !data) {
            return [[], null, []]; // Handle loading state gracefully
        }

        const shiftStartDate = new Date(selectedDate);
        shiftStartDate.setHours(shiftStartTime, 0, 0, 0);
        const shiftEndDate = new Date(shiftStartDate.getTime() + 24 * 60 * 60 * 1000);

        // Extract relevant tank data from the new format
        const extractTankRecords = (report) => {
            const tanks = report?.state?.reported?.tanks || {};

            return Object.entries(tanks).flatMap(([tankKey, tankDetails]) => {
                if (!tankDetails?.tank_data) {
                    return [];
                }

                return tankDetails.tank_data
                    .filter((tankRecord) => tankRecord.timestamp && !isNaN(new Date(tankRecord.timestamp).getTime())) // Skip invalid timestamps
                    .map((tankRecord) => ({
                        ...tankRecord,
                        tank_id: tankKey,
                        report_date: report.state.reported.system_date,
                        report_time: report.state.reported.system_time,
                    }));
            });
        };

        // Flatten tank data across all reports
        const allTankRecords = data.flatMap((report) => {
            try {
                return extractTankRecords(report);
            } catch (error) {
                return [];
            }
        });

        // Filter for records within the shift start and end time
        const dayData = allTankRecords.filter((record) => {
            const recordDate = new Date(record.timestamp);
            return recordDate >= shiftStartDate && recordDate < shiftEndDate;
        });

        // Sort tank data for the selected day by timestamp
        dayData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

        // Get the first record for the next day
        const nextDayData = allTankRecords.filter((record) => {
            const recordDate = new Date(record.timestamp);
            return recordDate >= shiftEndDate && recordDate < new Date(shiftEndDate.getTime() + 24 * 60 * 60 * 1000);
        });

        const nextDayFirstRecord = nextDayData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))[0];

        return [dayData, nextDayFirstRecord, []]; // Unique deliveries not needed for now
    }, [data, selectedDate, shiftStartTime, isReportsLoading]);

    // Debug Output
    console.log("Filtered Data:", filteredData);
    console.log("Next Day First Record:", nextDayFirstRecord);


    console.log("Day data: ", data);


    const handleInputChange = (tank, value) => {
        setMeterReadings({ ...meterReadings, [tank]: Number(value) });
    };

    const handleDownloadSIRReport = () => {
        if (deliveries && data && data.length > 0) {
            generateSIRPDF(data, deliveries, meterReadings, atgAttributes, selectedDate, logoImg, shiftStartTime);
        } else {
            console.error('No valid SIR reports available to download.');
        }
    };

    const handleDownloadLeakTestReport = () => {
        if (leakTestReports && leakTestReports.length > 0) {
            generateLeakTestPDF(leakTestReports, meterReadings, atgAttributes, selectedDate, logoImg);
        } else {
            console.error("No valid Leak Test reports available to download.");
        }
    };

    const shiftStart = filteredData[0]?.timestamp || 'N/A';
    const shiftEnd = nextDayFirstRecord?.timestamp || 'N/A';

    const handleSave = async () => {
        // Prepare the readings in the required format
        //console.log("Readings: ", meterReadings)
        const formattedReadings = Object.entries(meterReadings).map(([tank, reading]) => ({
            fuel_type: tank, // Assuming 'tank' corresponds to 'fuel_type'
            amount_dispensed: reading
        }));


        const readingsToSave = {
            organization_id: organization_id,
            atg_name: atg_name,
            readings: [{
                metric_date: selectedDate,
                fuel_readings: formattedReadings
            }]
        };

        try {
            // Trigger the API call
            await updateAtgMeterReadings(readingsToSave).unwrap();
            // Additional success handling logic
        } catch (error) {
            // Additional error handling logic
        }
    };

    console.log("Deliveries: ", deliveries);

    let content;

    if (isReportsLoading) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
                <Typography variant="h4" sx={{ ml: 2 }}>
                    Loading Daily Summary...
                </Typography>
            </Box>
        );
    } else if (isErrorReports) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="body1" color="error">
                    Error loading Daily Summary. Please try again later.
                </Typography>
            </Box>
        );
    } else if (deliveries.length === 0) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="body1">No data found.</Typography>
            </Box>
        );
    } else {
        content = (
            <Paper
                elevation={3}
                sx={{
                    backgroundColor: colors.primary[700],
                    padding: '20px',
                    borderRadius: '8px',
                    height: '100%', // Match parent height
                    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.4)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Non-scrollable area */}
                <Box sx={{ marginBottom: '10px' }}>
                    <Typography variant="h6">{`Selected Date: ${selectedDateString}`}</Typography>
                    <Typography>
                        {`Shift Start Time: ${shiftStart || '00:00'} | Shift End Time: ${shiftEnd || '23:59'}`}
                    </Typography>
                </Box>

                {/* Scrollable area including Save button */}
                <Box
                    sx={{
                        flex: 1, // Occupy remaining space
                        overflowY: 'auto', // Enable vertical scrolling
                    }}
                >
                    <Grid container spacing={1}>
                        {Object.entries(
                            tanksData.reduce((acc, tank) => {
                                acc[tank.tankKey] = acc[tank.tankKey] || [];
                                acc[tank.tankKey].push(tank);
                                return acc;
                            }, {})
                        ).map(([tankKey, tankRecords]) => {
                            const { startVolume, endVolume, deliveryVolume, volumeDiff, oversShorts } = calculateOversShorts(
                                tankKey,
                                meterReadings[tankKey] || 0
                            );

                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={4}
                                    key={tankKey}
                                >
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            backgroundColor: colors.primary[600],
                                            padding: '15px',
                                            borderRadius: '8px',
                                            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {/* Tank Data */}
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontWeight: 'bold',
                                                mb: 1,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {productLabels[tankKey] || tankKey.toUpperCase()}
                                        </Typography>

                                        <Typography sx={{ wordWrap: 'break-word' }}>{`Start Volume: ${startVolume}`}</Typography>
                                        <Typography sx={{ wordWrap: 'break-word' }}>{`End Volume: ${endVolume}`}</Typography>

                                        {deliveryVolume > 0 && (
                                            <Typography sx={{ color: 'green', wordWrap: 'break-word' }}>
                                                {`Delivery: ${deliveryVolume} gallons`}
                                            </Typography>
                                        )}

                                        <Typography sx={{ wordWrap: 'break-word' }}>{`Volume Difference: ${volumeDiff}`}</Typography>

                                        <Typography
                                            sx={{
                                                overflowWrap: 'break-word',
                                                wordWrap: 'break-word',
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            {`Overs/Shorts: ${startVolume} - ${endVolume} + ${deliveryVolume} = ${volumeDiff + deliveryVolume} (Book) - ${meterReadings[tankKey] || 0} (Sales) = ${oversShorts} gallons`}
                                        </Typography>

                                        {/* Meter Reading Input */}
                                        <TextField
                                            fullWidth
                                            type="number"
                                            label={`Meter Reading`}
                                            value={meterReadings[tankKey] || ''}
                                            onChange={(e) => handleInputChange(tankKey, e.target.value)}
                                            margin="dense"
                                            sx={{
                                                mt: 1,
                                                backgroundColor: colors.primary[500],
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                },
                                            }}
                                        />
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>

                    {/* Save Button inside scrollable area */}
                    <Box sx={{ marginTop: '20px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            sx={{
                                width: '100%',
                                backgroundColor: colors.primary[500],
                                '&:hover': {
                                    backgroundColor: colors.primary[700],
                                },
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Paper>
        );
    }

    return (
        <Paper
            style={{
                marginTop: '0px',
                padding: '10px',
                height: '500px', // Fixed height as per your requirement
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden', // Prevent content overflow
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                sx={{ borderBottom: '1px solid #ddd', pb: 2 }}
            >
                <Typography variant="h4" gutterBottom>
                    Daily Summary
                </Typography>
                <Box>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleDownloadSIRReport}
                        disabled={isReportsLoading}
                        sx={{ mr: 1 }}
                    >
                        Download SIR Report
                    </Button>
                    <Tooltip title="Leak Test Report is not available">
                        <Button
                            variant="outlined"
                            sx={{
                                color: colors.redAccent[200],
                                borderColor: colors.redAccent[200],
                                '&:hover': {
                                    backgroundColor: colors.redAccent[100],
                                },
                            }}
                        >
                            Download Leak Test Report
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            <Box sx={{ flex: 1, overflowY: 'auto' }}>
                {isReportsLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                        <Typography variant="h4" sx={{ ml: 2 }}>
                            Loading Daily Summary...
                        </Typography>
                    </Box>
                ) : (
                    content
                )}
            </Box>
        </Paper>
    );
};

export default DailySummary;
