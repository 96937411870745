import React, { useState } from 'react';
import { useTheme, Paper, Typography, Box, Collapse, IconButton, Menu, MenuItem } from "@mui/material";
import { CheckCircle, ErrorOutline, ExpandMore, ExpandLess, Download } from '@mui/icons-material';
import { useGetATGLeakDataQuery, useGetDistinctCSLDHistoryQuery, useGetAtgQuery } from '../../../../../features/atgs/atgsApiSlice'; // New CSLD history query import
import { tokens } from '../../../../../theme';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { usfuelblack } from '../../../../../assets';

const USFuelCSLD = ({ atg_name, config }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentDataOpen, setCurrentDataOpen] = useState(true);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const { data: leakData, isLoading: isLeakLoading } = useGetATGLeakDataQuery(atg_name);
    const { data: historyData, isLoading: isHistoryLoading } = useGetDistinctCSLDHistoryQuery(atg_name);

    const {
        data: atgReturn
    } = useGetAtgQuery(atg_name, {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const atgAttributes = atgReturn?.attributes;

    const tanks = leakData?.shadow?.tanks || {};

    // Parse fuel types from config
    const fuelTypes = Object.keys(config.tank_config || {}).reduce((acc, tankKey) => {
        acc[tankKey] = config.tank_config[tankKey].product_label;
        return acc;
    }, {});

    // Open/close the download menu
    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    // Group history data by report time
    const groupedReports = historyData
        ? Object.values(historyData).flat().reduce((acc, csldResult) => {
            const timeKey = csldResult.csld_test_start_time || "Unknown";
            if (!acc[timeKey]) acc[timeKey] = [];
            acc[timeKey].push(csldResult);
            return acc;
        }, {})
        : {};


    const generateCSLDPDF = (reportTime) => {
        const doc = new jsPDF();
        const logoImg = usfuelblack; // Your logo path

        const reportData = groupedReports[reportTime];
        if (!reportData) return;

        console.log("Report Data:", reportData); // Debugging

        // Map tank configurations based on the page logic
        const tankConfigs = reportData.map((csldResult) => {
            console.log("Tanks: ", tanks)
            console.log("csldResult: ", csldResult)
            const matchedTank = Object.entries(tanks).find(([tankId, tankData]) => {

                const tankLeakData = tanks[tankId]
                console.log("checking tank usfuel_csld_result: ", tankLeakData)
                return (

                    tankLeakData?.usfuel_csld_previous?.start_volume === csldResult.start_volume ||
                    tankLeakData?.full_height_volume === csldResult.start_volume
                );
            });

            if (matchedTank) {
                const [tankId, tankData] = matchedTank;
                const tankConfig = config.tank_config[tankId];

                // Properly capitalize the product label
                const productLabel =
                    tankConfig?.product_label.charAt(0).toUpperCase() +
                    tankConfig?.product_label.slice(1).toLowerCase();

                return {
                    tank_number: tankId.replace('tank_', ''),
                    product_label: productLabel || 'Unknown',
                    full_height_volume: tankConfig?.full_height_volume || 0,
                    tank_diameter: tankConfig?.tank_diameter || 0,
                };
            }

            // Fallback for unmatched tanks
            console.error("No matching tank found for csldResult:", csldResult);
            return {
                tank_number: `Unknown`,
                product_label: 'Unknown',
                full_height_volume: 0,
                tank_diameter: 0,
            };
        });


        // Format name and address by replacing underscores with spaces
        const formattedName = atgAttributes?.name?.replace(/_/g, ' ') || 'N/A';
        const formattedAddress = atgAttributes?.address?.replace(/_/g, ' ') || 'N/A';

        // Header Section
        doc.addImage(logoImg, 'PNG', 10, 10, 40, 10);
        doc.setFontSize(14); // Restored larger font size
        doc.text('CSLD Test Report', 60, 15);
        doc.setFontSize(12); // Restored larger font size for details
        doc.text(`ATG UST Location: ${formattedName}`, 10, 30);
        doc.text(`Address: ${formattedAddress}`, 10, 35);
        doc.text(`Report Time: ${reportTime}`, 10, 40);
        doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 10, 45);

        // Contact Info Box
        doc.setFillColor(200, 200, 200); // Grey background
        doc.roundedRect(140, 10, 55, 23, 2, 2, 'F'); // Rounded rectangle for the box
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Black text
        doc.text('Contact:', 142, 15);
        doc.text('usfuelpro.com', 142, 20);
        doc.text('512-923-8799', 142, 25);
        doc.text('dallas@usfuelpro.com', 142, 30);

        // Tank Info Table
        const tankInfoTable = tankConfigs.map((tank) => [
            `Tank ${tank.tank_number}`,
            tank.product_label || 'N/A',
            `${tank.full_height_volume} gal`,
            `${tank.tank_diameter} in`,
        ]);

        doc.autoTable({
            head: [['Tank', 'Product', 'Capacity', 'Diameter']],
            body: tankInfoTable,
            startY: 50,
            styles: { fontSize: 10 }, // Restored larger font size
            theme: 'grid',
        });

        // Test Results Section
        let startY = doc.lastAutoTable.finalY + 10;
        reportData.forEach((csldResult, index) => {
            // Check space and start on a new page if necessary
            if (startY + 50 > 260) {
                doc.addPage();
                startY = 20;
            }

            const tankConfig = tankConfigs[index];
            const tankName = tankConfig.product_label || `Tank ${tankConfig.tank_number}`;
            const isPass = csldResult.average_leak_rate <= 0.1;
            const status = isPass ? 'Passed' : 'Failed';
            const statusColor = isPass ? 'green' : 'red';

            // Tank Result Header
            doc.setFontSize(12); // Restored larger font size for tank section
            doc.setTextColor(statusColor);
            doc.text(`${tankName}: ${status}`, 10, startY);
            doc.setTextColor(0, 0, 0);

            // Extract the total volume decreased (if available)
            const totalVolumeDecreased =
                csldResult.total_volume_decreased !== undefined
                    ? csldResult.total_volume_decreased.toFixed(2)
                    : 'N/A';

            const startVolume = csldResult.start_volume || 'N/A';
            const endVolume = startVolume - totalVolumeDecreased || 'N/A';

            // Tank Test Details
            const tableData = [
                ['Test Start', csldResult.csld_test_start_time || 'N/A'],
                ['Test End', csldResult.csld_test_end_time || 'N/A'],
                ['Test Duration', csldResult.current_test_time_elapsed || 'N/A'],
                ['Start Volume', `${startVolume} gal`],
                ['End Volume', `${endVolume} gal`],
                ['Baseline Leak Rate', `${csldResult.average_leak_rate.toFixed(4)} gal/hr`],
                ['Total Volume Decreased', `${totalVolumeDecreased} gal`],
                ['Data Points Collected', `${csldResult.data_points_collected}`],
                [
                    'Summary (Observed Rates)',
                    `Min: ${csldResult.filtered_rates_summary.min.toFixed(2)}, Max: ${csldResult.filtered_rates_summary.max.toFixed(
                        2
                    )}, Avg: ${csldResult.filtered_rates_summary.average.toFixed(4)}`,
                ],
            ];

            doc.autoTable({
                head: [['Attribute', 'Value']],
                body: tableData,
                startY: startY + 5,
                styles: { fontSize: 10 }, // Restored larger font size for tank details
                theme: 'grid',
                pageBreak: 'avoid', // Avoid splitting across pages
            });

            startY = doc.lastAutoTable.finalY + 10;
        });

        // Save the PDF
        // Use the attributes `name` and `address` in the file name
        const fileName = `CSLD_Report_${atgAttributes?.name}_${atgAttributes?.address}_${reportTime.replace(/[: ]/g, '_')}.pdf`;
        doc.save(fileName);

    };





    return (
        <Box>
            {/* Current CSLD Data Section */}
            <Paper
                elevation={3}
                style={{
                    padding: '20px',
                    background: `linear-gradient(to bottom, ${colors.primary[400]}, ${colors.primary[600]})`,
                    marginBottom: '20px'
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" style={{ color: colors.greenAccent[300] }}>
                        Current CSLD Data
                    </Typography>
                    <IconButton onClick={() => setCurrentDataOpen(!currentDataOpen)}>
                        {currentDataOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                {!isLeakLoading && (<Collapse in={currentDataOpen}>
                    <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
                        {Object.entries(tanks).map(([tankId, tankData]) => {
                            const csldResult = tankData?.usfuel_csld_result;
                            const fuelType = fuelTypes[tankId] || `Tank ${tankId}`;
                            if (csldResult && (csldResult.status === "Running" || csldResult.status === "Stopped")) {
                                const isLeaking = csldResult.average_leak_rate > 0.1;
                                return (
                                    <TankStatusCard
                                        key={tankId}
                                        tankId={fuelType} // Use fuel type label here
                                        csldResult={{ ...csldResult, isLeaking }}
                                    />
                                );
                            }
                            return null;
                        })}
                    </Box>
                </Collapse>)}
            </Paper>

            {/* CSLD History Section */}
            <Paper
                elevation={3}
                style={{
                    padding: '20px',
                    background: `linear-gradient(to bottom, ${colors.blueAccent[600]}, ${colors.primary[600]})`
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" style={{ color: colors.greenAccent[300] }}>
                        CSLD History
                    </Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography
                            variant="body2"
                            style={{
                                color: colors.greenAccent[300],
                                textAlign: "center",
                            }}
                        >
                            Download CSLD Reports
                        </Typography>
                        <IconButton onClick={handleMenuOpen}>
                            <Download style={{ color: colors.greenAccent[300] }} />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            {Object.keys(groupedReports).map((timeKey) => (
                                <MenuItem
                                    key={timeKey}
                                    onClick={() => {
                                        generateCSLDPDF(timeKey);
                                        handleMenuClose();
                                    }}
                                >
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Download style={{ color: colors.greenAccent[300], fontSize: "1rem" }} />
                                        <Box>
                                            <Typography variant="body1">
                                                Report for: {timeKey}
                                            </Typography>
                                            <Typography variant="body2" style={{ color: "gray" }}>
                                                (Ran on this date)
                                            </Typography>
                                        </Box>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Menu>
                        <IconButton onClick={() => setHistoryOpen(!historyOpen)} style={{ fontSize: "1.5rem" }}>
                            {historyOpen ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                </Box>
                {!isHistoryLoading && (<Collapse in={historyOpen}>
                    <Box mt={2}>
                        {historyData && Object.keys(historyData).length > 0 ? (
                            Object.entries(historyData).map(([tankId, history]) => {
                                const fuelType = fuelTypes[tankId] || `Tank ${tankId}`;
                                return (
                                    <CSLDHistoryCard
                                        key={tankId}
                                        tankId={fuelType} // Use fuel type label here
                                        history={history}
                                    />
                                );
                            })
                        ) : (
                            <Typography variant="body2">No CSLD history data available for any tanks.</Typography>
                        )}
                    </Box>
                </Collapse>)}
            </Paper>
        </Box>
    );
};

const TankStatusCard = ({ tankId, csldResult }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Paper elevation={3} style={{
            padding: '15px',
            marginBottom: '15px',
            background: colors.primary[500],
            color: colors.primary[50]
        }}>
            <Box display="flex" alignItems="center" gap={2} mb={2}>
                {csldResult.isLeaking ? <ErrorOutline style={{ color: 'red' }} /> : <CheckCircle style={{ color: 'green' }} />}
                <Typography variant="h6" fontWeight="bold">
                    {tankId} - {csldResult.isLeaking ? "Inconclusive" : "Stable"}
                </Typography>
            </Box>
            <Typography variant="body1">Test Status: {csldResult.status} </Typography>
            <Typography variant="body1">Average Leak Rate: {csldResult.average_leak_rate.toFixed(4)} gal/hr</Typography>
            <Typography variant="body1">Lowest Leak Rate: {csldResult.lowest_leak_rate.toFixed(4)} gal/hr</Typography>
            <Typography variant="body1">Total Volume Decreased: {csldResult.total_volume_decreased.toFixed(2)} gal</Typography>
            <Typography variant="body1">Test Time Elapsed: {csldResult.current_test_time_elapsed}</Typography>
            <Typography variant="body1">Last Updated: {csldResult.timestamp}</Typography>
        </Paper>
    );
};

const CSLDHistoryCard = ({ tankId, history }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [expanded, setExpanded] = useState(false);

    return (
        <Paper elevation={3} style={{ padding: '15px', marginBottom: '15px', background: colors.primary[500], color: colors.primary[50] }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">{tankId} - Historical CSLD Data</Typography>
                <IconButton onClick={() => setExpanded(!expanded)}>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                {history && history.length > 0 ? (
                    history.map((csldResult, index) => {
                        const isPass = csldResult.average_leak_rate <= 0.1;
                        return (
                            <Box key={index} mt={2} borderTop={`1px solid ${colors.primary[300]}`} pt={2}>
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                    <Typography variant="h6" fontWeight="bold">
                                        Test at {csldResult.csld_test_start_time || "N/A"} - {tankId}
                                    </Typography>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography variant="h5" fontWeight="bold" style={{ color: isPass ? 'green' : 'red' }}>
                                            {isPass ? "Passed" : "Failed"}
                                        </Typography>
                                        {isPass ? (
                                            <CheckCircle style={{ color: 'green', fontSize: '1.5rem' }} />
                                        ) : (
                                            <ErrorOutline style={{ color: 'red', fontSize: '1.5rem' }} />
                                        )}
                                    </Box>

                                </Box>
                                <Box>
                                    <Typography variant="body1"><strong>Test Start:</strong> {csldResult.csld_test_start_time || "N/A"}</Typography>
                                    <Typography variant="body1"><strong>Test End:</strong> {csldResult.csld_test_end_time || "N/A"}</Typography>
                                    <Typography variant="body1"><strong>Average Leak Rate:</strong> {(csldResult.average_leak_rate || 0).toFixed(4)} gal/hr</Typography>
                                    <Typography variant="body1"><strong>Lowest Leak Rate:</strong> {(csldResult.lowest_leak_rate || 0).toFixed(4)} gal/hr</Typography>
                                    <Typography variant="body1"><strong>Total Volume Decreased:</strong> {(csldResult.total_volume_decreased || 0).toFixed(2)} gal</Typography>
                                    <Typography variant="body1"><strong>Data Points Collected:</strong> {csldResult.data_points_collected || 0}</Typography>
                                    <Typography variant="body1">
                                        <strong>Summary:</strong> Min: {(csldResult.filtered_rates_summary?.min || 0).toFixed(2)}, Max: {(csldResult.filtered_rates_summary?.max || 0).toFixed(2)}, Avg: {(csldResult.filtered_rates_summary?.average || 0).toFixed(4)}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })
                ) : (
                    <Typography variant="body2">No historical CSLD data available for this tank.</Typography>
                )}
            </Collapse>
        </Paper>
    );
};


export default USFuelCSLD;
