import React, { useState } from 'react';
import {
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    Box,
    useTheme,
    useMediaQuery,
    IconButton
} from '@mui/material';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { tokens } from '../../../../../theme';

const DeliveryLatest = ({ deliveries }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getLatestDeliveries = (deliveries) => {
        const tanks = Object.keys(deliveries?.tanks || {});
        return tanks
            .map((tankKey) => {
                const deliveryResults = deliveries.tanks[tankKey]?.delivery_result || [];
                const latestDelivery = deliveryResults
                    .filter((d) => d.delivery_volume)
                    .sort((a, b) => new Date(b.end_timestamp) - new Date(a.end_timestamp))[0];
                return latestDelivery ? { tankKey, latestDelivery } : null;
            })
            .filter(Boolean);
    };

    const latestDeliveries = getLatestDeliveries(deliveries);

    // State for collapsibility on mobile
    const [expanded, setExpanded] = useState(!isMobile); 
    const toggleExpand = () => setExpanded((prev) => !prev);

    return (
        <Paper
            elevation={2}
            sx={{
                padding: isMobile ? '10px' : '20px',
                marginTop: isMobile ? '10px' : '20px',
                background: `linear-gradient(135deg, ${colors.primary[400]}, ${colors.primary[700]})`,
                color: theme.palette.getContrastText(colors.primary[400]),
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={isMobile ? 1 : 2}>
                <Typography variant={isMobile ? "h5" : "h4"}>
                    Latest Deliveries
                </Typography>
                {isMobile && (
                    <IconButton onClick={toggleExpand} sx={{ color: '#fff' }}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                )}
            </Box>

            {(!isMobile || (isMobile && expanded)) && (
                <Card elevation={3}>
                    <CardContent
                        sx={{
                            height: isMobile ? 'calc(2.5em * 8)' : 'calc(2.5em * 10)',
                            overflowY: 'scroll',
                            padding: isMobile ? '10px' : '16px',
                            backgroundColor: colors.primary[600],
                            borderRadius: '8px',
                        }}
                    >
                        <List>
                            {latestDeliveries.map(({ tankKey, latestDelivery }) => (
                                <ListItem key={tankKey} sx={{ padding: isMobile ? '5px 0' : '8px 0' }}>
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            padding: isMobile ? '10px' : '15px',
                                            borderRadius: '10px',
                                            background: `linear-gradient(135deg, ${colors.primary[400]}, ${colors.primary[600]})`,
                                            width: '100%',
                                            color: theme.palette.getContrastText(colors.primary[400]),
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            mb={1}
                                            sx={{ gap: '10px' }}
                                        >
                                            <LocalShippingOutlined
                                                sx={{
                                                    fontSize: isMobile ? 25 : 30,
                                                }}
                                            />
                                            <Typography variant={isMobile ? 'h6' : 'h5'} gutterBottom>
                                                Tank {tankKey.replace('tank_', '')}
                                            </Typography>
                                        </Box>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <Typography variant="body1">
                                                        <strong>Delivery Volume:</strong> {latestDelivery.delivery_volume} gal
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        <strong>Start Time:</strong>{' '}
                                                        {new Date(latestDelivery.start_timestamp).toLocaleString()}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        <strong>End Time:</strong>{' '}
                                                        {new Date(latestDelivery.end_timestamp).toLocaleString()}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    </Paper>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            )}
        </Paper>
    );
};

export default DeliveryLatest;
