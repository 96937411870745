import React, { useState } from 'react';
import {
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    Box,
    useTheme,
    useMediaQuery,
    IconButton
} from "@mui/material";
import { useGetATGAlarmDataQuery } from '../../../../../features/atgs/atgsApiSlice';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import moment from 'moment';
import { tokens } from '../../../../../theme';

const Alarms = ({ atg_name }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { data, error, isLoading } = useGetATGAlarmDataQuery(atg_name);
    const alarms = data?.shadow?.alarms || [];

    // State for collapsibility on mobile
    const [expanded, setExpanded] = useState(!isMobile); 
    const toggleExpand = () => setExpanded((prev) => !prev);

    if (isLoading) return <Typography>Loading alarms...</Typography>;
    if (error) return <Typography>Error loading alarms: {error.message}</Typography>;

    return (
        <Paper
            elevation={2}
            sx={{
                padding: isMobile ? '10px' : '20px',
                marginTop: isMobile ? '10px' : '20px',
                background: `linear-gradient(135deg, ${colors.primary[400]}, ${colors.primary[700]})`,
                color: theme.palette.getContrastText(colors.primary[400]),
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={isMobile ? 1 : 2}>
                <Typography variant={isMobile ? "h5" : "h4"}>Alarms</Typography>
                {isMobile && (
                    <IconButton onClick={toggleExpand} sx={{ color: '#fff' }}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                )}
            </Box>

            {(!isMobile || (isMobile && expanded)) && (
                <Card elevation={3}>
                    <CardContent
                        sx={{
                            height: isMobile ? 'calc(2.5em * 8)' : 'calc(2.5em * 10)',
                            overflowY: 'scroll',
                            padding: isMobile ? '10px' : '16px',
                            backgroundColor: colors.primary[600],
                            borderRadius: '8px',
                        }}
                    >
                        <List>
                            {alarms.map((alarm, index) => {
                                const alarmTime = alarm.alarm_time ? moment(alarm.alarm_time) : null;
                                const timeAgo = alarmTime ? moment().diff(alarmTime, 'minutes') : null;

                                return (
                                    <ListItem key={index} sx={{ padding: isMobile ? '5px 0' : '8px 0' }}>
                                        <Paper
                                            elevation={2}
                                            sx={{
                                                padding: isMobile ? '10px' : '15px',
                                                borderRadius: '10px',
                                                background: `linear-gradient(135deg, ${colors.primary[400]}, ${colors.primary[600]})`,
                                                width: '100%',
                                                color: theme.palette.getContrastText(colors.primary[400]),
                                            }}
                                        >
                                            <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
                                                Alarm Details
                                            </Typography>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        <Typography variant="body1">
                                                            <strong>Category:</strong> {alarm.category || 'N/A'}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            <strong>Description:</strong> {alarm.alarm_description || 'N/A'}
                                                        </Typography>
                                                        {alarmTime && (
                                                            <Box display="flex" alignItems="center" mt={1}>
                                                                <AccessTimeIcon fontSize="small" sx={{ marginRight: '5px' }} />
                                                                <Typography variant="body2">
                                                                    {`${timeAgo} minutes ago`}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                }
                                            />
                                        </Paper>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </CardContent>
                </Card>
            )}
        </Paper>
    );
};

export default Alarms;
