import React, { useState, useMemo, useCallback } from 'react';
import {
    LineChart, Line, CartesianGrid, XAxis, YAxis,
    Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import {
    useTheme, Typography, FormControlLabel, Checkbox,
    Paper, IconButton, Menu, MenuItem
} from "@mui/material";
import { Box, styled } from '@mui/system';
import { tokens } from '../../../../../theme';
import SettingsIcon from '@mui/icons-material/Settings';

const domainRange = {
    day: [new Date(Date.now() - 24 * 60 * 60 * 1000).getTime(), Date.now()],
    week: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime(), Date.now()],
    month: [new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).getTime(), Date.now()],
    year: [new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).getTime(), Date.now()]
};

const ChartContainer = styled('div')({
    width: '100%',
    height: '400px',
});

const StyledPaper = styled(Paper)(({ theme, colors }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    background: `linear-gradient(135deg, ${colors.primary[200]} 0%, ${colors.primary[400]} 100%)`,
    borderRadius: '15px',
}));


const hasData = (data, tank) => {
    return data.some(point => point.state?.reported?.tanks[tank]?.tank_data?.[0]?.volume);
};

const ChartTab = ({ selectedTanks, handleTankCheck, data, range }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const tankColors = {
        tank_1: {
            volume: colors.yellowAccent[600], // Blue for tank 1
            ullage: colors.redAccent[900], // Darker shade for ullage
            temp: colors.greenAccent[200], // Darker shade for temperature
            height: colors.yellowAccent[400], // Darker shade for height
        },
        tank_2: {
            volume: colors.orangeAccent[400], // Indigo for tank 2
            ullage: colors.redAccent[800],
            temp: colors.orangeAccent[200],
            height: colors.redAccent[400],
        },
        tank_3: {
            volume: colors.violetAccent[500], // Violet for tank 3
            ullage: colors.redAccent[600],
            temp: colors.greenAccent[600],
            height: colors.orangeAccent[600],
        },
        tank_4: {
            volume: colors.blueAccent[500], // Yellow for tank 4
            ullage: colors.redAccent[500],
            temp: colors.greenAccent[500],
            height: colors.orangeAccent[500],
        }
    };



    const [showUllage, setShowUllage] = useState(false);
    const [showTemperature, setShowTemperature] = useState(false);


    // State for settings menu
    // State for settings menus
    const [anchorElVU, setAnchorElVU] = useState(null); // For Volume vs. Ullage menu
    const [anchorElTH, setAnchorElTH] = useState(null); // For Temperature vs. Height menu

    const handleVUMenuClick = (event) => setAnchorElVU(event.currentTarget);
    const handleTHMenuClick = (event) => setAnchorElTH(event.currentTarget);

    const handleMenuCloseVU = () => setAnchorElVU(null);
    const handleMenuCloseTH = () => setAnchorElTH(null);


    // Process data to add timestamp and tank data
    const processedData = useMemo(() => {
        return data.map(item => {
            const timestamp = new Date(`${item.state.reported.system_date} ${item.state.reported.system_time}`).getTime();
            const newItem = { timestamp };

            Object.keys(tankColors).forEach(tank => {
                const tankData = item.state.reported.tanks[tank]?.tank_data?.[0];
                if (tankData) {
                    newItem[`${tank}_volume`] = tankData.volume;
                    newItem[`${tank}_ullage`] = tankData.ullage;
                    newItem[`${tank}_temperature`] = tankData.temperature;
                    newItem[`${tank}_height`] = tankData.height;
                }
            });

            return newItem;
        });
    }, [data]);

    // Custom Tooltip memoized
    const CustomTooltip = useCallback(({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);

            return (
                <div style={{
                    backgroundColor: colors.primary[400],
                    padding: '10px',
                    borderRadius: '5px',
                    color: colors.primary[100],
                    boxShadow: `0 0 5px ${colors.primary[100]}`,
                }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{date.toLocaleString()}</p>
                    {payload.map((entry, index) => {
                        const entryName = String(entry.name || "Unknown"); // Ensure entryName is always a string
                        const unit = entryName.toLowerCase().includes('volume')
                            ? '(gal.)'
                            : entryName.toLowerCase().includes('temperature')
                                ? '(°F)'
                                : entryName.toLowerCase().includes('height')
                                    ? '(inches)'
                                    : ''; // Default to no unit if unrecognized
                        return (
                            <p
                                key={`item-${index}`}
                                style={{
                                    margin: 0,
                                    textShadow: `0 0 3px ${colors.primary[100]}`, // Adds subtle feather
                                }}
                            >
                                <span style={{ color: entry.stroke }}><strong>{entryName}</strong></span>: {entry.value} {unit}
                            </p>
                        );
                    })}
                </div>
            );
        }
        return null;
    }, [colors.primary]);



    // Memoized chart content
    const ChartContent = useMemo(() => (
        <LineChart data={processedData}>
            <CartesianGrid strokeDasharray="4 3" />
            <XAxis
                dataKey="timestamp"
                tickFormatter={(value) => {
                    const date = new Date(value);
                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                }}
                domain={[domainRange[range][0], domainRange[range][1]]}
                type="number"
                angle={-45}
                height={80}
                interval={range === 'week' ? 4 : range === 'month' ? 24 : range === 'year' ? 124 : 0}
                scale="time"
                tick={{ fill: 'white' }}
            />
            <YAxis
                yAxisId="left"
                orientation="left"
                stroke={colors.primary[600]}
                tick={{ fill: colors.primary[700], fontSize: '0.8rem', fontWeight: 'bold' }}
                label={{
                    value: 'Volume (Gallons)',
                    angle: -90,
                    position: 'insideLeft',
                    style: { fill: colors.primary[700], fontSize: '1.2rem', fontWeight: 'bold' }
                }}
            />
            {showUllage && (
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    stroke={colors.primary[100]}
                    tick={{ fill: colors.primary[200], fontSize: '0.875rem' }}
                    label={{
                        value: 'Ullage (Gallons)',
                        angle: -90,
                        position: 'insideRight',
                        style: { fill: colors.primary[100], fontSize: '1.2rem', fontWeight: 'bold' }
                    }}
                />
            )}
            <Tooltip content={<CustomTooltip />} />
            <Legend
                verticalAlign="top"
                height={36}
                wrapperStyle={{ fontSize: '1.2rem', color: colors.primary[700], fontWeight: 'bold' }}
            />

            {Object.keys(selectedTanks).map(tank => (
                selectedTanks[tank] && tankColors[tank] && (
                    <React.Fragment key={tank}>
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey={`${tank}_volume`}
                            name={`Tank ${tank.split('_')[1]} Volume`}
                            stroke={tankColors[tank].volume}
                            strokeWidth={3}
                            dot={false}
                        />
                        {showUllage && (
                            <Line
                                yAxisId="right"
                                type="monotone"
                                dataKey={`${tank}_ullage`}
                                name={`Tank ${tank.split('_')[1]} Ullage`}
                                stroke={tankColors[tank].ullage}
                                strokeWidth={3}
                                dot={false}
                            />
                        )}
                    </React.Fragment>
                )
            ))}
        </LineChart>
    ), [processedData, selectedTanks, showUllage, range, colors, CustomTooltip]);

    return (
        <div>

            {Object.keys(tankColors).map(tank => (
                hasData(data, tank) && (
                    <FormControlLabel
                        key={tank}
                        control={
                            <Checkbox
                                checked={selectedTanks[tank]}
                                onChange={() => handleTankCheck(tank)}
                                name={tank}
                                color="secondary"
                            />
                        }
                        label={
                            <>
                                <span style={{ color: tankColors[tank].volume, marginRight: '5px' }}>●</span>
                                {`Show Tank ${tank.split('_')[1]}`}
                            </>
                        }
                    />
                )
            ))}
            <Box style={{ display: 'flex', justifyContent: 'left' }}>
                <Typography variant="h3">Volume Vs. Ullage</Typography>
                <IconButton onClick={handleVUMenuClick}>
                    <SettingsIcon />
                </IconButton>
            </Box>

            <div style={{ display: 'flex', alignItems: 'center' }}>


                <Menu
                    anchorEl={anchorElVU}
                    open={Boolean(anchorElVU)}
                    onClose={handleMenuCloseVU}
                >
                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showUllage}
                                    onChange={(e) => setShowUllage(e.target.checked)}
                                />
                            }
                            label="Show Ullages"
                        />
                    </MenuItem>
                </Menu>
            </div>
            <StyledPaper theme={theme} colors={colors}>
                <ChartContainer>
                    <ResponsiveContainer>
                        {ChartContent}
                    </ResponsiveContainer>
                </ChartContainer>
            </StyledPaper>
            <Box style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <Typography variant="h3">Temperature Vs. Height</Typography>
                <IconButton onClick={handleTHMenuClick}>
                    <SettingsIcon />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorElTH}
                open={Boolean(anchorElTH)}
                onClose={handleMenuCloseTH}
            >
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showTemperature}
                                onChange={(e) => setShowTemperature(e.target.checked)}
                            />
                        }
                        label="Show Temperature"
                    />
                </MenuItem>
            </Menu>
            <StyledPaper theme={theme} colors={colors}>
                <ChartContainer>
                    <ResponsiveContainer>
                        <LineChart data={processedData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="timestamp"
                                tickFormatter={(value) => {
                                    const date = new Date(value);
                                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                                }}
                                domain={[domainRange[range][0], domainRange[range][1]]}
                                type="number"
                                angle={-45}
                                height={80}
                                interval={range === 'week' ? 4 : range === 'month' ? 24 : range === 'year' ? 99 : 0}
                                scale="time"
                                tick={{ fill: 'white' }}
                            />
                            <YAxis
                                yAxisId="left"
                                orientation="left"
                                stroke="#d88484"
                                tick={{ fill: colors.primary[700], fontSize: '0.9rem', fontWeight: 'bold' }}
                                label={{
                                    value: 'Temperature (°F)',
                                    angle: -90,
                                    position: 'insideLeft',
                                    style: { fill: colors.primary[700], fontSize: '1.2rem', fontWeight: 'bold' }
                                }}
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                stroke="#d8a484"
                                tick={{ fill: colors.primary[200], fontSize: '0.9rem', fontWeight: 'bold' }}
                                label={{
                                    value: 'Height (inches)',
                                    angle: 90,
                                    position: 'insideRight',
                                    style: { fill: colors.primary[100], fontSize: '1.2rem', fontWeight: 'bold' }
                                }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            {Object.keys(selectedTanks).map(tank => (
                                selectedTanks[tank] && tankColors[tank] && (
                                    <React.Fragment key={tank}>
                                        {showTemperature && (
                                            <Line
                                                key={`${tank}_temperature`}
                                                yAxisId="left"
                                                type="monotone"
                                                dataKey={`${tank}_temperature`}
                                                name={`Tank ${tank.split('_')[1]} Temperature`}
                                                stroke={tankColors[tank].temp}
                                                strokeWidth={3}
                                                dot={false}
                                            />
                                        )}
                                        <Line
                                            key={`${tank}_height`}
                                            yAxisId="right"
                                            type="monotone"
                                            dataKey={`${tank}_height`}
                                            name={`Tank ${tank.split('_')[1]} Height`}
                                            stroke={tankColors[tank].height}
                                            strokeWidth={3}
                                            dot={false}
                                        />
                                    </React.Fragment>
                                )
                            ))}
                        </LineChart>

                    </ResponsiveContainer>
                </ChartContainer>
            </StyledPaper>

        </div>
    );
};

export default ChartTab;
